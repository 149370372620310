import React from 'react';
import CommentFooter from './CommentFooter';
import AnswerFooter from './AnswerFooter';



let components = {
    answer: AnswerFooter,
    qcomment: CommentFooter
};

class Footer extends React.Component {
    render() {
        let { item: { type } } = this.props;
        let Component = components[type];

        if (!Component) {
            return null;
        }

        return (
            <Component { ...this.props } />
        );
    }
}

export default Footer;
