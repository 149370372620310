import host from './../http/host';
import { Request } from '@/jrequester';
import Maybe from '@/monads/maybe';



export default {
    bindPhone: (payload) => {
        let { data, token } = payload;

        let request = new Request({
            method: 'POST',
            url: host.get() + '/rest/question-leads/',
            withCredentials: true,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data
        });

        request
            .response()
            .OK()
            .map((response) => (
                Maybe
                    .from(response)
                    .filter((response) => response.meta.code === 200)
                    .map((response) => response.data)
            ));

        return request;
    },

    getSelf: (payload) => {
        let { token } = payload;

        let request = new Request({
            method: 'GET',
            url: host.get() + '/rest/users/self/',
            withCredentials: true,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        request
            .response()
            .OK()
            .map((response) => (
                Maybe
                    .from(response)
                    .filter((response) => response.meta.code === 200)
                    .map((response) => response.data)
                    .map((data) => data.users)
                    .filter((users) => users.length && users.length > 0)
                    .map((users) => users[0])
            ));

        return request;
    },

    authorize: (payload) => {
        let { data } = payload;

        let request = new Request({
            method: 'POST',
            url: host.get() + '/rest/auth-oauth/',
            withCredentials: true,
            responseType: 'json',
            data
        });

        request
            .response()
            .OK()
            .map((response) => (
                Maybe
                    .from(response)
                    .filter((response) => response.meta.code === 200)
                    .map((response) => response.data)
            ));

        return request;
    },

    register: (payload) => {
        let { data } = payload;

        let request = new Request({
            method: 'POST',
            url: host.get() + '/rest/registration-oauth/',
            withCredentials: true,
            responseType: 'json',
            data
        });

        request
            .response()
            .OK()
            .map((response) => (
                Maybe
                    .from(response)
                    .filter((response) => response.meta.code === 200)
                    .map((response) => response.data)
            ));

        return request;
    }
};
