import React from 'react';
import './attached-files.css';
import AttachedFile from './AttachedFile';
import actions from './../../actions';
import classnames from 'classnames';



class AttachedFiles extends React.Component {
    deleteHandler = (id) => {
        let { dispatch, namespace } = this.props;
        dispatch(actions.files.asyncDeleteFile({id, namespace}));
    };

    render() {
        let { files, className, size } = this.props;

        if (!files || files.length === 0) {
            return null;
        }

        className = classnames('attached-files', {
            [className]: className,
            [`attached-files--${size}`]: size
        });

        return (
            <div className={className}>
                <ul className="attached-files__list">
                    {
                        files.map((file) => {
                            let { id } = file;
                            return (
                                <AttachedFile
                                    key={id}
                                    size={size}
                                    className="attached-files__file"
                                    { ...file }
                                    onDelete={this.deleteHandler}
                                />
                            );
                        })
                    }
                </ul>
            </div>
        );
    }
}

export default AttachedFiles;
