import React from 'react';
import { Spinner } from '@vkontakte/vkui';
import Icon28Newsfeed from '@vkontakte/icons/dist/28/newsfeed';
import Icon24Help from '@vkontakte/icons/dist/24/help';
import Panels from './panels/Panels';
import Tabs from './tabs/Tabs';
import Tutorial from './tutorial/Tutorial';



let panels = [
	{name: 'form'},
	{name: 'question'},
	{name: 'questions'}
];
let tabs = [
	{Icon: Icon24Help, name: 'form', text: 'Задать вопрос'},
	{Icon: Icon28Newsfeed, name: 'questions', text: 'Мои вопросы'}
];

class Container extends React.Component {
    render() {
        let { questions, question, dispatch, loading, user, alert, modal, files, form, buffer, panel, tutorial, onChangePanel } = this.props;

        if (tutorial.isShow) {
			return (
				<Tutorial loading={loading.tutorial} { ...tutorial } dispatch={dispatch} />
			);
		}

		if (loading.main) {
			return (
				<Spinner size='large' />
			);
		}

		return (
			<React.Fragment>
				<Tabs
					items={tabs}
					onChange={onChangePanel}
					activeTab={panel.active}
				/>
				<Panels
					user={user}
					form={form}
					alert={alert}
					panel={panel}
					modal={modal}
					files={files}
					buffer={buffer}
					panels={panels}
					loading={loading}
					dispatch={dispatch}
					question={question}
					questions={questions}
					onChangePanel={onChangePanel}
				/>
			</React.Fragment>
		);
    }
}

export default Container;
