import React from 'react';
import classnames from 'classnames';
import { Link, Spinner } from '@vkontakte/vkui';
import './card.css';
import Details from './details/Details';
import DetailForm from './detail-form/DetailForm';
import Files from './../../../files/Files';
import actions from './../../../../actions';
import { scroller } from 'react-scroll';



const MIN_FRAME_HEIGHT = 200;
const MIN_FRAME_HEIGHT_AMENDMENT = 50;

class Card extends React.Component {
    contentHeight = 0;

    constructor(props) {
        super(props);

        this.checkContentHeight(props.text);

        this.state = {
            isShowDetailForm: false,
            isOpen: !this.isHeedCloseContent()
        };
    }

    componentDidUpdate(prevProps, prevState) {
        let { isShowDetailForm: isShowDetailFormPrev } = prevState;
        let { isShowDetailForm: isShowDetailFormCurr } = this.state;
        let isFormClosed = isShowDetailFormPrev && !isShowDetailFormCurr;
        isFormClosed && this.deleteAllFiles();
    }

    deleteAllFiles() {
        let { dispatch } = this.props;
        dispatch(actions.files.asyncDeleteAll({namespace: this.namespace()}));
    }

    toggleDetailForm() {
        let { isShowDetailForm } = this.state;
        this.setState({
            isShowDetailForm: !isShowDetailForm
        });
    }

    checkContentHeight(text) {
        let tmp = document.createElement('div');
        tmp.classList.add('question-card__content');
        tmp.classList.add('question-card__content--tmp');
        tmp.innerHTML = text;
        document.body.appendChild(tmp);
        this.contentHeight = parseFloat(getComputedStyle(tmp).height, 10);
        document.body.removeChild(tmp);
    }

    isHeedCloseContent() {
        return this.contentHeight > MIN_FRAME_HEIGHT + MIN_FRAME_HEIGHT_AMENDMENT;
    }

    namespace() {
        let { id } = this.props;
        return 'q_details_' + id;
    }

    addFilesHandler = (e) => {
        let { files } = e.target;
        let { dispatch } = this.props;
        dispatch(actions.files.asyncAdd({files, namespace: this.namespace()}));
        e.target.value = '';
    };

    openClickHandler = () => {
        this.setState({
            isOpen: true
        })
    };

    submitDetailHandler = ({text}, callbacks) => {
        let { dispatch } = this.props;
        let { reject } = callbacks;
        let namespace = this.namespace();
        dispatch(actions.question.asyncAddComment({
            namespace,
            data: {
                text
            }
        }, (entity) => {
            this.setState({
                isShowDetailForm: false
            });
            setTimeout(() => {
                scroller.scrollTo('detail-' + entity.id, {
                    duration: 800,
                    delay: 0,
                    offset: -70,
                    smooth: 'easeInOutQuart'
                });
            });
        }, () => {}, () => {
            reject && reject();
        }));
    };

    addDetailClickHandler = () => {
        this.toggleDetailForm();
    };

    render() {
        let { title, text, dispatch, details, files, loading, attachedFiles } = this.props;
        let { isShowDetailForm, isOpen } = this.state;
        let frameClassName = classnames('question-card__frame', {
            'question-card__frame--opened': isOpen,
            'question-card__frame--closed': this.isHeedCloseContent(),
            'question-card__frame--shaded': !isOpen,
            'pointer': !isOpen
        });

        return (
            <div className="question-card">
                <div className="question-card__title">{title}</div>
                <div className={frameClassName} onClick={this.openClickHandler}>
                    <div className="question-card__content contentable" dangerouslySetInnerHTML={{ __html: text}} />
                    <Files items={files} className="question-card__files" downloadable dispatch={dispatch} />
                    {
                        loading ?
                        <Spinner size="medium" /> :
                        <Details items={details} dispatch={dispatch} />
                    }
                    <Link className="pointer" style={{fontSize: '14px'}} onClick={this.addDetailClickHandler}>Добавить уточнение</Link>
                    {
                        isShowDetailForm &&
                        <DetailForm
                            dispatch={dispatch}
                            onSubmit={this.submitDetailHandler}
                            onAddFiles={this.addFilesHandler}
                            namespace={this.namespace()}
                            files={attachedFiles[this.namespace()]}
                        />
                    }
                </div>
                {
                    !isOpen &&
                    <div className="question-card__opener-container pointer" onClick={this.openClickHandler}>
                        <Link style={{fontSize: '14px'}} >Развернуть</Link>
                    </div>
                }
            </div>
        );
    }
}

export default Card;
