class ServerListener {
    constructor(request, timeout) {
        this.timeout = timeout || ServerListener.DEFAULT_TIMEOUT;
        this.request = request;
        this.intervalID = null;
        this.requests = {};
    }

    run() {
        let self = this;
        this.intervalID = setInterval(() => {
            let id = '#' + Math.random();
            let req = self.request();
            req
                .response()
                .OK()
                .subscribe(() => delete self.requests[id]);

            self.requests[id] = req;
        }, 1000 * this.timeout);
    }

    break() {
        this.intervalID = clearInterval(this.intervalID);
        Object.keys((this.requests)).forEach((key) => this.requests[key].abort());
    }
}

ServerListener.DEFAULT_TIMEOUT = 5;

export default ServerListener;
