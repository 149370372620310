let symbols = {
    '\n': true,
    ' ': true
};

export default {
    normalize: (text) => {
        text = text || '';
        text = text.trim();
        return text.split('').reduce((total, symbol) => {
            if (symbols[symbol] && symbols[total[total.length - 1]]) {
                return total;
            }
            return total += symbol;
        }, '');
    }
};
