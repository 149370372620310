export default function(name, storage, syncDelay) {
    let s = {
        data: {},
        getItem: function(key) {
            return this.data[key];
        },
        setItem: function(key, value) {
            this.data[key] = value;
        },
        removeItem: function(key) {
            delete this.data[key];
        }
    };
    let baseKey = '#state#' + name + ':';

    let timeouts = {};

    return {
        save: storage ? function(key, value) {
            if (syncDelay) {
                clearTimeout(timeouts[key]);
                timeouts[key] = setTimeout(() => {
                    storage.setItem(baseKey + key, JSON.stringify(value));
                    timeouts[key] = null;
                }, syncDelay);
                s.setItem(key, value);
            } else {
                storage.setItem(baseKey + key, JSON.stringify(value));
            }
        } : function(key, value) {
            s.setItem(baseKey + key, value);
        },
        restore: storage ? function(key) {
            if (timeouts[key]) {
                return s.getItem(key);
            }
            return JSON.parse(storage.getItem(baseKey + key));
        } : function(key) {
            return s.getItem(baseKey + key);
        },
        clear: storage ? function(key) {
            clearTimeout(timeouts[key]);
            timeouts[key] = null;
            s.removeItem(baseKey + key);
            storage.removeItem(baseKey + key);
        } : function(key) {
            s.removeItem(key);
        }
    };
}
