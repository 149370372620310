import { PANEL } from './../constants';
import update from 'immutability-helper';



let initialState = {
    active: 'form',
    locked: false,
    history: ['form']
};

let reducer = (state = initialState, action) => {
    let { type } = action;
    switch (type) {
        case PANEL.SET: {
            let { active, history } = action.payload;
            return update(state, {
                active: {
                    $set: active
                },
                history: {
                    $set: history
                }
            });
        }
        case PANEL.LOCK: {
            return update(state, {
                locked: {
                    $set: true
                }
            });
        }
        case PANEL.UNLOCK: {
            return update(state, {
                locked: {
                    $set: false
                }
            });
        }
        default: {
            return state;
        }
    }
};

export default reducer;
