import host from './../http/host';
import { Request } from '@/jrequester';
import Maybe from '@/monads/maybe';
import merge from 'lodash/merge';



let parseBatch = (batch) => (
    Maybe
        .from(batch)
        .filter((response) => response.length && response.length > 0)
        .map((response) => response.filter((item) => item && item.meta.code === 200 && item.data))
        .map((response) => response.reduce((acc, item) => merge(acc, item.data), {}))
        .filter((data) => Object.keys(data).length > 0)
);

export default {
    get: (payload) => {
        let { token, data: { id } } = payload;

        let batch = [
            {
                method: 'GET',
                relative_url: '/rest/questions/' + id + '/'
            }
        ];

        let request = new Request({
            method: 'POST',
            url: host.get() + '/rest/batch/',
            withCredentials: true,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                batch: JSON.stringify(batch)
            }
        });

        request
            .response()
            .OK()
            .map((response) => parseBatch(response).map((data) => data.questions[0]));

        return request;
    },

    post: (payload) => {
        let { token, data } = payload;

        let request = new Request({
            method: 'POST',
            url: host.get() + '/rest/questions-oauth/',
            withCredentials: true,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data
        });

        request
            .response()
            .OK()
            .map((response) => (
                Maybe
                    .from(response)
                    .filter((response) => response.meta.code === 200)
                    .map((response) => response.data)
            ));

        return request;
    },

    rateAnswer: (payload) => {
        let { data, token } = payload;

        let request = new Request({
            method: 'POST',
            url: host.get() + '/rest/rate/',
            withCredentials: true,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data
        });

        request
            .response()
            .OK()
            .map((response) => (
                Maybe
                    .from(response)
                    .filter((response) => response.meta.code === 200)
                    .map((response) => response.data)
                    .map((data) => data.entity_id)
            ));

        return request;
    },

    addComment: (payload) => {
        let { data, token } = payload;

        let request = new Request({
            method: 'POST',
            url: host.get() + '/rest/question-comments/?return_entity=1',
            withCredentials: true,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data
        });

        request
            .response()
            .OK()
            .map((response) => (
                Maybe
                    .from(response)
                    .filter((response) => response.meta.code === 200)
                    .map((response) => response.data)
                    .map((data) => data.entity)
            ));

        return request;
    },

    getAnswers: (payload) => {
        let { token, data: { id } } = payload;

        let batch = [
            {
                method: 'GET',
                relative_url: '/rest/questions/' + id + '/'
            },
            {
                method: 'GET',
                relative_url: '/rest/questions/' + id + '/answers/'
            }
        ];

        let request = new Request({
            method: 'POST',
            url: host.get() + '/rest/batch/',
            withCredentials: true,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                batch: JSON.stringify(batch)
            }
        });

        request
            .response()
            .OK()
            .map((response) => (
                parseBatch(response)
                    .filter((data) => data.questions && data.questions[0])
                    .map((data) => ({
                        tree: data.questions[0].discussion_tree || [],
                        answers: Object.keys(data.answers).length === 0 ? {} : data.answers
                    }))
            ));

        return request;
    },

    getComments: (payload) => {
        let { token, data: { id } } = payload;

        let batch = [
            {
                method: 'GET',
                relative_url: '/rest/questions/' + id + '/'
            },
            {
                method: 'GET',
                relative_url: '/rest/questions/' + id + '/comments/'
            }
        ];

        let request = new Request({
            method: 'POST',
            url: host.get() + '/rest/batch/',
            withCredentials: true,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                batch: JSON.stringify(batch)
            }
        });

        request
            .response()
            .OK()
            .map((response) => (
                parseBatch(response)
                .filter((data) => data.questions && data.questions[0])
                .map((data) => ({
                    tree: data.questions[0].discussion_tree || [],
                    comments: Object.keys(data.question_comments).length === 0 ? {} : data.question_comments
                }))
            ));

        return request;
    }
};
