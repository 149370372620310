class Maybe {
    static just(value) {
        return new Just(value);
    }

    static nothing() {
        return new Nothing();
    }

    static from(value) {
        return value === null || value === undefined ? Maybe.nothing() : Maybe.just(value);
    }

    static of(value) {
        return Maybe.just(value);
    }
}

class Just {
    constructor(value) {
        this._value = value;
    }

    map(fn) {
        return Maybe.from(fn(this._value));
    }

    filter(fn) {
        return Maybe.from(fn(this._value) ? this._value : null);
    }

    getOrElse() {
        return this._value;
    }
}

class Nothing {
    map() {
        return this;
    }

    filter() {
        return this;
    }

    getOrElse(other) {
        return other;
    }
}

export default Maybe;
