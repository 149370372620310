import React from 'react';
import Like from './../../../../rates/Like';
import actions from './../../../../../../../../actions';



class RateNegativeStateLike extends React.Component {
    clickHandler = () => {
        let { onRunPending, onBreakPending, isPending, isDisebled, dispatch, feedback, entityId } = this.props;
        if (isPending) {
            return;
        }
        if (isDisebled) {
            return;
        }
        onRunPending();
        dispatch(actions.question.asyncAnswerRedislike({id: entityId, feedback}, {
            success: () => {
                dispatch(actions.question.asyncAnswerLike({id: entityId}, {
                    success: () => onBreakPending(),
                    reject: () => onBreakPending()
                }));
            },
            reject: () => onBreakPending()
        }));
    };

    render() {
        let { value } = this.props;

        return (
            <Like value={value} onLike={this.clickHandler} className="pointer" />
        );
    }
}

export default RateNegativeStateLike;
