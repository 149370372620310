import React from 'react';
import './item/item.css';
import './discussion.css';
import Item from './item/Item';
import Empty from './Empty';



class Discussion extends React.Component {
    render() {
        let { user, discussion, dispatch, files } = this.props;

        if (!discussion || discussion.length === 0) {
            return (
                <Empty />
            );
        }

        return (
            <div className="discussion">
                {
                    discussion.map((item, i) => {
                        let isLast = discussion.length - 1 === i;
                        let isNeedIndent = (!item.children || item.children.length === 0) && !isLast;
                        let style = {
                            marginBottom: isNeedIndent ? '24px' : 0
                        };
                        return (
                            <div key={item.id} style={style}>
                                <Item type="root" item={item} currentUser={user} dispatch={dispatch} files={files} />
                            </div>
                        );
                    })
                }
            </div>
        );
    }
}

export default Discussion;
