import React from 'react';
import classnames from 'classnames';
import './rates.css';



class Rates extends React.Component {
    render() {
        let { like, dislike, className } = this.props;
        className = classnames('rates', {
            [className]: className
        });

        return (
            <div className={className}>
                <div className="rates__item">{like}</div>
                <div className="rates__item">{dislike}</div>
            </div>
        );
    }
}

export default Rates;
