import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';



let store = createStore(
    combineReducers({
        ...reducers
    }),
    {},
    applyMiddleware(
        () => (next) => (action) => {
            next(action);
        },
        thunk
    )
);

export default store;
