export default {
    FILE: {
        DONE: 'FILE_DONE',
        ERROR: 'FILE_ERROR',
        WAITING: 'FILE_WAITING',
        LOADING: 'FILE_LOADING',
        DELETING: 'FILE_DELETING'
    },
    USER: {
        AUTH: 'USER_AUTH',
        NEW: 'USER_NEW'
    }
};
