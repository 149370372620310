import { NOTICE } from './../constants';



let permissions = {
    waiting: true
};

let allowed = (status) => permissions[status];

let empty = (queue) => queue.length === 0;

let model = {
    show: (payload) => (dispatch) => {
        let { text } = payload;

        dispatch(model.push({text}));
        dispatch(model.start());
    },

    start: () => (dispatch, getState) => {
        let { notice: { status, queue } } = getState();

        if (allowed(status) && !empty(queue)) {
            dispatch(model.setStatus({
                status: 'starting'
            }));
        }
    },

    enter: () => (dispatch) => {
        dispatch(model.setStatus({
            status: 'entering'
        }));
    },

    leave: () => (dispatch) => {
        dispatch(model.setStatus({
            status: 'leaveing'
        }));
    },

    done: () => (dispatch) => {
        dispatch(model.setStatus({
            status: 'done'
        }));
    },

    wait: () => (dispatch) => {
        dispatch(model.setStatus({
            status: 'waiting'
        }))
    },

    end: () => (dispatch) => {
        dispatch(model.shift());
        dispatch(model.wait());
        dispatch(model.start());
    },

    clear: () => ({
        type: NOTICE.CLEAR
    }),

    push: (payload) => ({
        type: NOTICE.PUSH,
        payload
    }),

    shift: () => ({
        type: NOTICE.SHIFT
    }),

    setStatus: (payload) => ({
        type: NOTICE.SET_STATUS,
        payload
    })
};

export default model;
