import { FORM } from './../constants';
import STATUSES from './../statuses';
import POPUPS from './../popups';
import MODALS from './../modals';
import actions from './index';
import text from './../helpers/text';
import formStorage from './../storages/form';
import { getToken } from './../utils/token';
import { postQuestion, getSelfQuestions, bindUserPhone } from './../fetchers';



const NAMESPACE = 'question';

function getFiles(state) {
    let ids = [];
    let files = state.files[NAMESPACE];
    files = files || [];
    files.forEach((file) => {
        ids.push(file.id);
    });
    return ids;
}

function checkLoadingFiles(files) {
    files = files || [];
    files = files.filter((file) => file.status === STATUSES.FILE.LOADING);
    return files.length > 0;
}

let model = {
    asyncSubmit: () => (dispatch, getState) => {
        let state = getState();

        let { files, user, form } = state;

        let getTokenByVKUserID = getToken(user.vk.id);

        if (checkLoadingFiles(files[NAMESPACE])) {
            let alert = {
                title: 'Не все файлы загружены',
                name: POPUPS.NOT_ALL_FILES_UPLOADED,
                actions: [{
                    title: 'OK',
                    autoclose: true
                }]
            };
            dispatch(actions.alert.open({data: alert}));
            return;
        }

        dispatch(model.onPending());

        let postQuestionRequest = () => {
            return postQuestion({
                token: getTokenByVKUserID(),
                data: {
                    text: text.normalize(form.data.question.text),
                    files: getFiles(state)
                }
            })
                .failed(() => dispatch(model.offPending()))
                .response()
                .OK()
                .subscribe((data) => {
                    data
                        .filter((data) => data.status === 'success')
                        .map((data) => data.questionId)
                        .map((questionId) => {
                            getSelfQuestions({token: getTokenByVKUserID()})
                                .response()
                                .OK()
                                .subscribe((wrap) => {
                                    wrap
                                        .filter((questions) => questions.length && questions.length > 0)
                                        .map((questions) => {
                                            dispatch(model.clear());
                                            dispatch(model.offPending());
                                            dispatch(actions.files.clear({namespace: NAMESPACE}));

                                            dispatch(actions.questions.setList({list: questions}));
                                            dispatch(actions.question.asyncSelect({id: questionId}));
                                            dispatch(actions.panel.forward({active: 'question'}));
                                        });
                                });
                        });

                    data
                        .filter((data) => data.status === 'error')
                        .map((data) => data.errors)
                        .map((errors) => {
                            dispatch(actions.alert.open({data: {
                                title: 'Вопрос не опубликован',
                                name: POPUPS.INVALIDATED_QUESTION,
                                data: {
                                    errors
                                },
                                actions: [{
                                    title: 'OK',
                                    autoclose: true
                                }]
                            }}));
                            dispatch(model.offPending());
                        });
                })
                .TooManyRequests()
                .subscribe(() => {
                    dispatch(actions.alert.open({data: {
                        title: 'Слишком частая подача вопроса',
                        name: POPUPS.QUESTION_SPAM,
                        actions: [{
                            title: 'OK',
                            autoclose: true
                        }]
                    }}));
                    dispatch(model.offPending());
                });
        };

        if (user.prvd.phone) {
            postQuestionRequest();
        } else {
            dispatch(actions.modal.open({data: {
                name: MODALS.SUBMIT_PHONE_SUGGESTION,
                title: 'Ваш телефон',
                height: 100,
                onClose: () => {
                    postQuestionRequest();
                    dispatch(actions.modal.close());
                },
                data: {
                    onSend: (phone) => {
                        postQuestionRequest()
                            .OK()
                            .subscribe((data) => {
                                data
                                    .filter((data) => data.status === 'success')
                                    .map((data) => data.questionId)
                                    .map((questionId) => {
                                        bindUserPhone({
                                            token: getTokenByVKUserID(),
                                            data: {
                                                phone,
                                                question_id: questionId
                                            }
                                        })
                                            .response()
                                            .OK()
                                            .subscribe(() => {
                                                dispatch(actions.user.update({
                                                    type: 'prvd',
                                                    data: {
                                                        phone
                                                    }
                                                }));
                                            });
                                    });
                            });
                        dispatch(actions.modal.close());
                    },
                    onSkip: () => {
                        postQuestionRequest();
                        dispatch(actions.modal.close());
                    }
                }
            }}));
        }
    },

    clear: () => ({
        type: FORM.CLEAR
    }),

    setData: (payload) => ({
        type: FORM.SET_DATA,
        payload
    }),

    change: (payload) => ({
        type: FORM.CHANGE,
        payload
    }),

    onPending: () => ({
        type: FORM.ON_PENDING
    }),

    offPending: () => ({
        type: FORM.OFF_PENDING
    })
};

export default model;
