import React from 'react';
import Dislike from './../../../../rates/Dislike';
import actions from './../../../../../../../../actions';



class NotRateStateDislike extends React.Component {
    clickHandler = () => {
        let { onRunPending, onBreakPending, isPending, isDisebled, dispatch, entityId } = this.props;
        if (isPending) {
            return;
        }
        if (isDisebled) {
            return;
        }
        onRunPending();
        dispatch(actions.question.asyncAnswerDislike({id: entityId}, {
            success: () => onBreakPending(),
            reject: () => onBreakPending()
        }));
    };

    render() {
        let { value } = this.props;

        return (
            <Dislike value={value} onDislike={this.clickHandler} className="pointer" />
        );
    }
}

export default NotRateStateDislike;
