import React from 'react';
import classnames from 'classnames';



class Progress extends React.Component {
    render() {
        let { data } = this.props;

        if (!data) {
            return null;
        }

        let { data: { title, color, percent } } = this.props;
        let lineClassName = classnames('form-progress__line', {
            [`form-progress__line--${color}`]: color
        });
        let lineStyle = {
            width: `${percent}%`
        };

        return (
            <div className="form-progress">
                <div className="form-progress__title">{title}</div>
                <div className={lineClassName} style={lineStyle}></div>
            </div>
        );
    }
}

export default Progress;
