import React from 'react';
import Detail from './detail/Detail';



class Details extends React.Component {
    render() {
        let { items, dispatch } = this.props;

        if (!items || items.length === 0) {
            return null;
        }

        return (
            <ul className="client-details">
                {
                    items.map((item) => (
                        <Detail key={item.id} { ...item } dispatch={dispatch} />
                    ))
                }
            </ul>
        );
    }
}

export default Details;
