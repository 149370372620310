import { LOADING } from './../constants';



let model = {
    break: (payload) => ({
        type: LOADING.BREAK,
        payload
    }),

    run: (payload) => ({
        type: LOADING.RUN,
        payload
    })
};

export default model;
