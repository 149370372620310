import React from 'react';
import classnames from 'classnames';
import RelativeDate from './../../../../../RelativeDate';
import Files from './../../../../../files/Files';
import { Link } from '@vkontakte/vkui';



const MIN_FRAME_HEIGHT = 70;
const MIN_FRAME_HEIGHT_AMENDMENT = 50;

class Detail extends React.Component {
    contentHeight = 0;

    constructor(props) {
        super(props);

        this.checkContentHeight(props.text);

        this.state = {
            isOpen: !this.isHeedCloseContent()
        };
    }

    checkContentHeight(text) {
        let tmp = document.createElement('div');
        tmp.classList.add('client-detail__content');
        tmp.classList.add('client-detail__content--tmp');
        tmp.innerHTML = text;
        document.body.appendChild(tmp);
        this.contentHeight = parseFloat(getComputedStyle(tmp).height, 10);
        document.body.removeChild(tmp);
    }

    isHeedCloseContent() {
        return this.contentHeight > MIN_FRAME_HEIGHT + MIN_FRAME_HEIGHT_AMENDMENT;
    }

    openClickHandler = () => {
        this.setState({
            isOpen: true
        })
    };

    render() {
        let { text, timeadd, id, files, dispatch } = this.props;
        let { isOpen } = this.state;
        let innerClassName = classnames('client-detail__inner', {
            'client-detail__inner--opened': isOpen,
            'client-detail__inner--closed': this.isHeedCloseContent() && !isOpen,
            'client-detail__inner--shaded': !isOpen,
            'pointer': !isOpen
        });

        return (
            <li id={'detail-' + id} className="client-detail client-details__item">
                <div className="client-detail__title">Уточнение</div>
                <div className={innerClassName} onClick={this.openClickHandler}>
                    <div className="client-detail__content contentable" dangerouslySetInnerHTML={{ __html: text}} />
                    <Files items={files} className="client-detail__files" downloadable dispatch={dispatch} />
                    <div className="client-detail__footer">
                        <div>
                            <div className="client-detail__meta">
                                <RelativeDate datetime={timeadd} />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !isOpen &&
                    <div className="client-detail__opener-container pointer" onClick={this.openClickHandler}>
                        <Link style={{fontSize: '14px'}} >Развернуть</Link>
                    </div>
                }
            </li>
        );
    }
}

export default Detail;
