import React from 'react';
import { Header, PanelHeader, Spinner, Div, Link } from '@vkontakte/vkui';
import './questions.css';
import Item from './item/Item';
import actions from './../../../actions';
import Maybe from '@/monads/maybe';



class Questions extends React.Component {
    limit = 10;
    offset = 0;

    isLoad = false;

    constructor(props) {
        super(props);

        this.state = {
            isPending: false
        };
    }

    componentDidMount() {
        let { loading, questions } = this.props;

        this.setOffset(questions.length);
        this.setLoadPermission(questions.length);

        !loading.questions && this.getQuestions();

        window.addEventListener('scroll', this.scrollHandler);
    }

    componentDidUpdate() {
        this.setOffset(this.props.questions.length);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
    }

    setOffset(value) {
        this.offset = value;
    }

    setLoadPermission(count) {
        this.isLoad = count === this.limit;
    }

    getQuestions() {
        let { dispatch } = this.props;

        dispatch(actions.questions.asyncGet({
            success: (questions) => {
                this.setLoadPermission(questions.length);
            }
        }));
    }

    loadQuestions() {
        let { dispatch } = this.props;

        dispatch(actions.questions.asyncLoad(this.limit, this.offset, {
            success: (questions) => {
                this.setState({
                    isPending: false,
                });
                this.setLoadPermission(questions.length);
            }
        }));
    }

    getListBottom() {
        return (
            Maybe
                .from(this.refList)
                .map((element) => element.getBoundingClientRect())
                .map((rect) => rect.bottom)
                .getOrElse(0)
        );
    }

    getDocumentHeight() {
        return (
            Maybe
                .from(document.documentElement)
                .map((element) => getComputedStyle(element))
                .map((style) => style.height)
                .map((height) => parseFloat(height, 10))
                .getOrElse(0)
        );
    }

    isScrolledToEndList() {
        let listBottom = this.getListBottom();
        let documentHeight = this.getDocumentHeight();

        return listBottom && documentHeight ? listBottom <= documentHeight : false;
    }

    isCanLoad() {
        let { isPending } = this.state;

        return this.isScrolledToEndList() && !isPending && this.isLoad;
    }

    scrollHandler = () => {
        if (this.isCanLoad()) {
            this.setState({
                isPending: true
            });
            this.loadQuestions();
        }
    };

    selectHandler = (id) => {
        let { onChangePanel, dispatch } = this.props;
        id = parseInt(id, 10);
        onChangePanel && onChangePanel('question', {question_id: id});
        dispatch(actions.question.asyncSelect({id}));
    };

    setRefList = (ref) => {
        this.refList = ref;
    };

    render() {
        let { questions, loading } = this.props;
        let { isShowMore, isLoading } = this.state;

        return (
            <React.Fragment>
                <PanelHeader>Мои вопросы</PanelHeader>
                {
                    loading.questions ?
                    <Spinner size="large" style={{marginTop: '50px'}} /> :
                    <div style={{padding: '0 0 48px 0'}}>
                        {
                            !questions || questions.length === 0 ?
                            <Header>Вы еще не задали ни одного вопроса.</Header> :
                            <div>
                                <ul className="questions" ref={this.setRefList}>
                                    {
                                        questions.map((question) => {
                                            let { id } = question;
                                            return (
                                                <Item key={id} { ...question } onClick={this.selectHandler} />
                                            );
                                        })
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default Questions;
