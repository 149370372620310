import React from 'react';
import classnames from 'classnames';
import { Alert } from '@vkontakte/vkui';
import POPUPS from './../../popups';
import InvalidatedFilesPopup from './popups/invalidated-files/InvalidatedFilesPopup';
import NotAllFilesUploadedPopup from './popups/not-all-files-uploaded/NotAllFilesUploadedPopup';
import QuestionSpamPopup from './popups/question-spam/QuestionSpamPopup';
import InvalidatedQuestionPopup from './popups/invalidated-question/InvalidatedQuestionPopup';



let elements = {
    [POPUPS.INVALIDATED_FILES]: InvalidatedFilesPopup,
    [POPUPS.NOT_ALL_FILES_UPLOADED]: NotAllFilesUploadedPopup,
    [POPUPS.QUESTION_SPAM]: QuestionSpamPopup,
    [POPUPS.INVALIDATED_QUESTION]: InvalidatedQuestionPopup
};

class Popup extends React.Component {
    render() {
        let { title, name, data, actions, dispatch, user, size, onClose } = this.props;
        let Element = elements[name];

        if (!Element) {
            return null;
        }

        let className = classnames('popup', {
            [`popup--${size}`]: size
        });

        return (
            <Alert
                className={className}
                actionsLayout="vertical"
                actions={actions}
                onClose={onClose}>
                <h2 style={{fontWeight: 'bold'}}>{title}</h2>
                <Element { ...data } dispatch={dispatch} user={user} />
            </Alert>
        );
    }
}

export default Popup;
