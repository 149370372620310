import React from 'react';
import { Cell, Avatar } from '@vkontakte/vkui';
import host from './../../../../../../../../../http/host';



class UserComment extends React.Component {
    render() {
        let { currentUser: { name, avatar } } = this.props;

        return (
            <Cell before={<Avatar src={host.get() + avatar} />}>
                <span className="discussion-item__name--bold">{name}</span>
            </Cell>
        );
    }
}

export default UserComment;
