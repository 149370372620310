import React from 'react';
import User from './user/User';
import Icon24ReplyOutline from '@vkontakte/icons/dist/24/reply_outline';



class Header extends React.Component {
    render() {
        let { type, user, currentUser } = this.props;

        return (
            <div className="discussion-item__header">
                <div className="discussion-item__sub-arrow">
                    <Icon24ReplyOutline fill="#2787f5" />
                </div>
                <User type={type} user={user} currentUser={currentUser} />
            </div>
        );
    }
}

export default Header;
