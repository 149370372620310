import React from 'react';
import Like from './../../../../rates/Like';



class RatePositiveStateLike extends React.Component {
    render() {
        let { value } = this.props;

        return (
            <Like value={value} className="not-allowed" />
        );
    }
}

export default RatePositiveStateLike;
