import React from 'react';
import './notice.css';
import animate from '@/animate';
import actions from './../../actions';
import Maybe from '@/monads/maybe';



const LIFETIME = 3;

class Notice extends React.PureComponent {
    componentDidUpdate(prevProps) {
        let { status: prevStatus } = prevProps;
        let { status: currentStatus, dispatch } = this.props;

        if (currentStatus !== prevStatus) {
            switch (currentStatus) {
                case 'starting':
                    dispatch(actions.notice.enter());

                    this.enter();

                    break;
                default:

            }
        }
    }

    enter() {
        let { dispatch } = this.props;

        animate({
            duration: 500,
            timing: (fraction) => Math.pow(fraction, 2),
            draw: (progress) => {
                this.selfRef.style.display = 'block';
                this.selfRef.style.opacity = progress;
            },
            done: () => {
                dispatch(actions.notice.done());

                setTimeout(() => {
                    dispatch(actions.notice.leave());

                    this.leave();
                }, LIFETIME * 1000);
            }
        });
    }

    leave() {
        let { dispatch } = this.props;

        animate({
            duration: 500,
            timing: (fraction) => Math.pow(fraction, 2),
            draw: (progress) => {
                this.selfRef.style.opacity = 1 - progress;
            },
            done: () => {
                this.selfRef.style.display = 'none';
                dispatch(actions.notice.end());
            }
        });
    }

    first() {
        let { queue } = this.props;
        return queue[0];
    }

    setSelfRef = (ref) => {
        this.selfRef = ref;
    };

    render() {


        return (
            Maybe
                .from(this.first())
                .map((first) => <div ref={this.setSelfRef} className="notice">{first.text}</div>)
                .getOrElse(null)
        );
    }
}

export default Notice;
