import user from './user';
import form from './form';
import alert from './alert';
import modal from './modal';
import panel from './panel';
import files from './files';
import notice from './notice';
import buffer from './buffer';
import loading from './loading';
import tutorial from './tutorial';
import question from './question';
import questions from './questions';



export default {
    user,
    form,
    alert,
    modal,
    panel,
    files,
    buffer,
    notice,
    loading,
    tutorial,
    question,
    questions
};
