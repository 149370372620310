import React from 'react';
import Root from './components/root/Root';
import Children from './components/children/Children';
import { scroller } from 'react-scroll';
import actions from './../../../../../actions';



let components = {
    root: Root,
    children: Children
};

let scrolled = false;

class Item extends React.PureComponent {
    componentDidMount() {
        let { item: { id, state, type }, dispatch } = this.props;
        if (state === 'new') {
            if (!scrolled) {
                scrolled = true;
                setTimeout(() => {
                    scroller.scrollTo('answer-' + id, {
                        duration: 800,
                        delay: 0,
                        offset: -70,
                        smooth: 'easeInOutQuart'
                    });
                    scrolled = false;
                });
            }
            if (type === 'answer') {
                dispatch(actions.question.updateAnswers({
                    items: {
                        [id]: {
                            state: 'old'
                        }
                    }
                }));
            }
        }
    }

    getId() {
        let { item: { id, type } } = this.props;
        return type + '-' + id;
    }

    render() {
        let { type } = this.props;
        let Component = components[type];

        return (
            <div id={this.getId()} className="discussion-item discussion__item">
                <Component { ...this.props } />
            </div>
        );
    }
}

export default Item;
