import React from 'react';
import Dislike from './../../../../rates/Dislike';



class RatePositiveStateDislike extends React.Component {
    render() {
        let { value } = this.props;

        return (
            <Dislike value={value} className="not-allowed" />
        );
    }
}

export default RatePositiveStateDislike;
