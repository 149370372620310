import { FILES } from './../constants';
import STATUSES from './../statuses';
import POPUPS from './../popups';
import actions from './index';
import { uploadFile, deleteFile, getFile } from './../fetchers';
import { getToken } from './../utils/token';



const MAX_FILES_COUNT = 5;
const MAX_FILE_SIZE = 20971520;

let acceptFileTypes = [
    'png', 'jpg', 'jpeg', 'jpe', 'gif', 'bmp', 'tif', 'tiff',
    'txt', 'rtf', 'csv', 'xml', 'doc', 'docx', 'odt', 'xls',
    'xlsx', 'odf', 'ods', 'pps', 'ppt', 'pptx', 'pdf', 'epub',
    'odf', 'ods', 'zip', 'rar'
];

let permissions = {
    delete: {
        [STATUSES.FILE.DONE]: true,
        [STATUSES.FILE.ERROR]: true,
        [STATUSES.FILE.LOADING]: true
    }
};

let requests = {};

requests.store = {};

requests.save = (id, req) => {
    requests.store[id] = req;
};

requests.delete = (id) => {
    requests.store[id] && delete requests.store[id];
};

requests.abort = (id) => {
    requests.store[id] && requests.store[id].abort();
};

requests.destroy = (id) => {
    requests.abort(id);
    requests.delete(id);
};

function getFileFromFiltered(id, files) {
    let filteredFiles = files.filter((file) => file.id === id);
    return filteredFiles.length > 0 ? filteredFiles[0] : null;
}

let model = {
    asyncAdd: (payload) => (dispatch, getState) => {
        let state = getState();
        let rawFiles = payload.files;

        let { namespace } = payload;
        let { user } = state;

        let processingFiles = state.files[namespace];

        let validatedFiles = [];
        let invalidatedFiles = {
            bySize: [],
            byType: []
        };

        rawFiles = [].slice.call(rawFiles);

        rawFiles.forEach((rawFile) => {
            let { size, name } = rawFile;
            if (size > MAX_FILE_SIZE) {
                invalidatedFiles.bySize.push(rawFile);
            } else {
                let splittedName = name.split('.');
                if (splittedName.length > 1) {
                    let extension = splittedName[splittedName.length - 1];
                    let isAcceptFileType = acceptFileTypes.indexOf(extension) >= 0;
                    if (isAcceptFileType) {
                        validatedFiles.push(rawFile);
                    } else {
                        rawFile.extension = extension;
                        invalidatedFiles.byType.push(rawFile);
                    }
                }
            }
        });

        let alert = {
            title: 'Обратите внимание',
            name: POPUPS.INVALIDATED_FILES,
            data: {
                invalidatedFiles: {},
                extraFiles: [],
                config: {
                    maxFilesCount: MAX_FILES_COUNT,
                    maxFileSize: MAX_FILE_SIZE
                }
            },
            actions: [{
                title: 'OK',
                autoclose: true
            }]
        };

        if (invalidatedFiles.bySize.length > 0) {
            alert.data.invalidatedFiles.bySize = invalidatedFiles.bySize;
        }

        if (invalidatedFiles.byType.length > 0) {
            alert.data.invalidatedFiles.byType = invalidatedFiles.byType;
        }

        let processingFilesCount = (processingFiles && processingFiles.length) || 0;
        let isMaxFilesCountReached = processingFilesCount + validatedFiles.length > MAX_FILES_COUNT;
        let filesCountAvailableToUpload = MAX_FILES_COUNT - processingFilesCount;
        let extraFiles = validatedFiles.slice(filesCountAvailableToUpload);

        validatedFiles = validatedFiles.slice(0, filesCountAvailableToUpload);
        alert.data.extraFiles = extraFiles;
        alert.data.isMaxFilesCountReached = isMaxFilesCountReached;

        if (invalidatedFiles.bySize.length > 0 || invalidatedFiles.byType.length > 0 || isMaxFilesCountReached) {
            dispatch(actions.alert.open({data: alert}));
        }

        if (validatedFiles.length === 0) {
            return;
        }

        let filesWithTempIds = [];
        let uniqueFileId = 0;

        for (let i = 0; i < validatedFiles.length; i++) {
            let rawFile = validatedFiles[i];
            let id = `#temp:${uniqueFileId}:${Math.random()}`;
            let { name } = rawFile;
            let file = {
               id,
               name,
               progress: 0,
               file: rawFile,
               status: STATUSES.FILE.WAITING
            };
            uniqueFileId++;
            filesWithTempIds.push(file);
        }

        dispatch(model.add({files: filesWithTempIds, namespace}));

        filesWithTempIds.forEach((fileWithTempId) => {
            dispatch(model.loadingFile({
                id: fileWithTempId.id,
                namespace
            }));
            dispatch(model.asyncUploadFile({
                namespace,
                file: {
                    ...fileWithTempId
                }
            }));
        });
    },

    asyncUploadFile: ({file, namespace}) => (dispatch, getState) => {
        let { user } = getState();

        let getTokenByVKUserID = getToken(user.vk.id);

        let uploadFileRequest = uploadFile({
            token: getTokenByVKUserID(),
            data: {
                file,
                namespace
            }
        });

        uploadFileRequest
            .upload()
            .progress()
            .subscribe(({id, value, namespace}) => {
                dispatch(model.progressFile({
                    id,
                    value,
                    namespace
                }));
            });

        uploadFileRequest
            .failed(() => dispatch(model.errorFile({id: file.id, namespace})))
            .response()
            .OK()
            .subscribe((wrap) => {
                wrap.map((id) => {
                    dispatch(model.updateFileId({currentId: file.id, newId: id, namespace}));
                    dispatch(model.doneFile({id, namespace}));
                    requests.delete(file.id);
                });
            });

        requests.save(file.id, uploadFileRequest);
    },

    asyncDeleteFile: (payload) => (dispatch, getState) => {
        let state = getState();

        let { namespace } = payload;
        let { user } = state;

        let file = getFileFromFiltered(payload.id, state.files[namespace]);
        let permission = permissions.delete[file.status];
        let getTokenByVKUserID = getToken(user.vk.id);

        if (!permission) {
            return;
        }

        dispatch(model.deletingFile({id: file.id, namespace}));

        if (file.status === STATUSES.FILE.LOADING) {
            requests.destroy(file.id);

            dispatch(model.deleteFile({id: file.id, namespace}));
        } else {
            deleteFile({
                token: getTokenByVKUserID(),
                data: {
                    id: file.id
                }
            })
                .failed(() => dispatch(model.errorFile({id: file.id, namespace})))
                .response()
                .OK()
                .subscribe(() => {
                    dispatch(model.deleteFile({id: file.id, namespace}));
                });
        }
    },

    asyncGetFile: (payload) => (dispatch, getState) => {
        let state = getState();
        let { id } = payload;
        let { user } = state;
        let getTokenByVKUserID = getToken(user.vk.id);

        getFile({
            token: getTokenByVKUserID(),
            data: {
                id: id
            }
        })
            .response()
            .OK()
            .subscribe((blob) => {
                let url = URL.createObjectURL(blob);
                let a = document.createElement('a');

                a.href = url;
                a.download = `prvd-${id}`;

                a.addEventListener('click', function click() {
                    setTimeout(() => {
                        URL.revokeObjectURL(url);
                        a.removeEventListener('click', click);
                    }, 100);
                }, false);

                a.click();
            });
    },

    asyncDeleteAll: (payload) => (dispatch, getState) => {
        let { files } = getState();
        let { namespace } = payload;

        files = files[namespace];
        files = files || [];
        files.forEach((file) => {
            dispatch(model.asyncDeleteFile({
                id: file.id,
                namespace
            }));
        });
    },

    add: (payload) => ({
        type: FILES.ADD,
        payload
    }),

    deleteFile: (payload) => ({
        type: FILES.DELETE_FILE,
        payload
    }),

    getFile: (payload) => ({
        type: FILES.GET_FILE,
        payload
    }),

    deletingFile: (payload) => ({
        type: FILES.DELETING_FILE,
        payload
    }),

    loadingFile: (payload) => ({
        type: FILES.LOADING_FILE,
        payload
    }),

    doneFile: (payload) => ({
        type: FILES.DONE_FILE,
        payload
    }),

    progressFile: (payload) => ({
        type: FILES.PROGRESS_FILE,
        payload
    }),

    updateFileId: (payload) => ({
        type: FILES.UPDATE_FILE_ID,
        payload
    }),

    errorFile: (payload) => ({
        type: FILES.ERROR_FILE,
        payload
    }),

    clear: (payload) => ({
        type: FILES.CLEAR,
        payload
    })
};

export default model;
