import qs from 'qs';



let { location } = window;
const PREFIX = 'vk_';

export default {
    get: () => {
        let search = location.search.substr(1);
        let params = qs.parse(search);
        params = params || {};
        let result = {
            service_oauth_type: 'vk_mini_apps',
            service_user_id: params.vk_user_id,
            sign: params.sign
        };
        Object.keys(params).forEach((key) => {
            if (key.substr(0, PREFIX.length) === PREFIX) {
                result[key] = params[key];
            }
        });
        return result;
    }
};
