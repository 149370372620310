import React from 'react';
import classnames from 'classnames';
import Icon24ThumbDown from '@vkontakte/icons/dist/24/thumb_down';
import './rate.css';



class Like extends React.Component {
    render() {
        let { value = 0, onDislike, className } = this.props;

        className = classnames('rate-item', {
            [className]: className
        });

        return (
            <div className={className} onClick={onDislike}>
                <Icon24ThumbDown fill="#e64646" />
                <span>{value}</span>
            </div>
        );
    }
}

export default Like;
