import React from 'react';
import Header from './header/Header';
import Inner from './inner/Inner';



const MIN_FRAME_HEIGHT = 70;
const MIN_FRAME_HEIGHT_AMENDMENT = 50;

class Children extends React.Component {
    contentHeight = 0;

    constructor(props) {
        super(props);

        this.checkContentHeight(props.item.text);

        this.state = {
            isOpen: !this.isHeedCloseContent()
        };
    }

    checkContentHeight(text) {
        let tmp = document.createElement('div');
        tmp.classList.add('discussion-item__content');
        tmp.classList.add('discussion-item__content--tmp');
        tmp.innerHTML = text;
        document.body.appendChild(tmp);
        this.contentHeight = parseFloat(getComputedStyle(tmp).height, 10);
        document.body.removeChild(tmp);
    }

    isHeedCloseContent() {
        return this.contentHeight > MIN_FRAME_HEIGHT + MIN_FRAME_HEIGHT_AMENDMENT;
    }

    openClickHandler = () => {
        this.setState({
            isOpen: true
        })
    };

    render() {
        let { item: { type, user }, currentUser, dispatch, files } = this.props;
        let { isOpen } = this.state;
        let isHeedCloseContent = this.isHeedCloseContent();

        return (
            <div className="discussion-item__main">
                <Header type={type} user={user} currentUser={currentUser} />
                <Inner
                    item={this.props.item}
                    files={files}
                    isOpen={isOpen}
                    isHeedCloseContent={isHeedCloseContent}
                    currentUser={currentUser}
                    onClick={this.openClickHandler}
                    dispatch={dispatch}
                />
            </div>
        );
    }
}

export default Children;
