import rest from './../rest';
import launchParams from './../helpers/launch-params';
import store from './../store';
import actions from './../actions/index';
import { setToken } from './../utils/token';
import Maybe from '@/monads/maybe';



const RETRIES_RUN_COUNT = 3;

export let authorize = (payload) => run(rest.user.authorize(payload));

export let register = (payload) => run(rest.user.register(payload));

export let getTutorial = () => run(rest.tutorial.get());

export let getSelfUser = (payload) => run(rest.user.getSelf(payload));

export let getSelfQuestions = (payload) => run(rest.questions.getSelfList(payload));

export let postQuestion = (payload) => run(rest.question.post(payload));

export let bindUserPhone = (payload) => run(rest.user.bindPhone(payload));

export let addQuestionComment = (payload) => run(rest.question.addComment(payload));

export let getQuestion = (payload) => run(rest.question.get(payload));

export let getQuestionAnswers = (payload) => run(rest.question.getAnswers(payload));

export let getQuestionComments = (payload) => run(rest.question.getComments(payload));

export let rateQuestionAnswer = (payload) => run(rest.question.rateAnswer(payload));

export let uploadFile = (payload) => run(rest.file.upload(payload));

export let deleteFile = (payload) => run(rest.file.delete(payload));

export let getFile = (payload) => run(rest.file.get(payload));

let { dispatch, getState } = store;

let getLaunchParams = () => ({ ...launchParams.get() });

let getVKUserIDFromState = (state) => state.user.vk.id;

let offline = () => dispatch(actions.notice.show({text: 'При загрузке данных произошла ошибка. Проверьте ваше подключение к сети.'}));

let reauthorize = () => (
    authorize({data: getLaunchParams()})
        .response()
        .OK()
        .map((wrap) => (
            wrap
                .filter((data) => data.status === 'success')
                .map((data) => data.access_token)
        ))
        .subscribe((wrap) => wrap.map((token) => setToken(getVKUserIDFromState(getState()))(token)))
);

let prerunner = (request) => (resolve) => {
    reauthorize()
        .OK()
        .subscribe((wrap) => {
            wrap.map((token) => {
                request.mutate({
                    headers: {
                        Authorization: 'Bearer ' + token
                    }
                });
                resolve();
            });
        });
};

let rerun = (request) => () => request.run(prerunner(request));

function run(request) {
    request
        .configure({retries: RETRIES_RUN_COUNT})
        .failed(() => getState().notice.queue.length === 0 && offline())
        .response()
        .OK().subscribe(() => dispatch(actions.notice.clear()))
        .Forbidden().subscribe(rerun(request))
        .Unauthorized().subscribe(rerun(request));

    return request.run();
};
