import { BUFFER } from './../constants';



let model = {
    write: (payload) => ({
        type: BUFFER.WRITE,
        payload
    }),

    clear: () => ({
        type: BUFFER.CLEAR
    })
};

export default model;
