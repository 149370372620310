import React from 'react';
import NotRateStateLike from './NotRateStateLike';
import RatePositiveStateLike from './RatePositiveStateLike';
import RateNegativeStateLike from './RateNegativeStateLike';



let components = {
    not_rate: NotRateStateLike,
    rate_positive: RatePositiveStateLike,
    rate_negative: RateNegativeStateLike
};

class Like extends React.Component {
    render() {
        let { state } = this.props;
        let Component = components[state];

        if (!Component) {
            return null;
        }

        return (
            <Component { ...this.props } />
        );
    }
}

export default Like;
