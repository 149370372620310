import React from 'react';
import './invalidated-question-popup.css';



class InvalidatedQuestionPopup extends React.Component {
    render() {
        let { errors = [] } = this.props;

        return (
            <div className="invalidated-question-popup">
                <ul>
                    {
                        errors.map((error, i) => {
                            let { message } = error;

                            return (
                                <li key={i}>- {message}</li>
                            );
                        })
                    }
                </ul>
            </div>
        );
    }
}

export default InvalidatedQuestionPopup;
