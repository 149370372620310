import React from 'react';
import ClientRater from './client/ClientRater';



let components = {
    client: ClientRater
};

class Rater extends React.PureComponent {
    render() {
        let { role } = this.props;
        let Component = components[role];

        if (!Component) {
            return null;
        }

        return (
            <Component { ...this.props } />
        );
    }
}

export default Rater;
