import React from 'react';
import ClientMyQuestionRater from './my-question-rater/ClientMyQuestionRater';



let components = {
    my: ClientMyQuestionRater
};

class ClientRater extends React.Component {
    render() {
        let { utensils } = this.props;
        let Component = components[utensils];

        if (!Component) {
            return null;
        }

        return (
            <Component { ...this.props } />
        );
    }
}

export default ClientRater;
