import { TUTORIAL } from './../constants';
import update from 'immutability-helper';



let initialState = {
    isShow: false,
    items: []
};

let reducer = (state = initialState, action) => {
    let { type } = action;
    switch (type) {
        case TUTORIAL.SET: {
            let { items } = action.payload;
            return update(state, {
                items: {
                    $set: items
                }
            });
        }
        case TUTORIAL.HIDE: {
            return update(state, {
                isShow: {
                    $set: false
                }
            });
        }
        case TUTORIAL.SHOW: {
            return update(state, {
                isShow: {
                    $set: true
                }
            });
        }
        default: {
            return state;
        }
    }
};

export default reducer;
