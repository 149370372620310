import { PANEL } from './../constants';
import vkConnect from './../vk-connect/vk-connect';
import { IS_PLATFORM_ANDROID } from '@vkontakte/vkui/dist/lib/platform';
import url from './../helpers/url';
import { isSafari } from '@/browser';



let isSame = (current, target) => () => current === target;

let isLocked = (panel) => () => panel.locked;

let isForbidden = (...fns) => fns.reduce((value, fn) => {
    return value ? value : fn();
}, false);

let model = {
    forward: (payload) => (dispatch, getState) => {
        let { panel } = getState();
        let { params = {} } = payload;
        let target = payload.active;

        if (isForbidden(isLocked(panel), isSame(panel.active, target))) {
            return;
        }

        if (panel.active === 'form') {
            vkConnect.send('VKWebAppEnableSwipeBack');
        }

        let history = [ ...panel.history ];
        history.push(target);

        !isSafari() && dispatch(model.lock());
        dispatch(model.set({
            active: target,
            history
        }));

        !isSafari() && window.history.pushState({panel: target}, target, url.query(params));
    },

    back: () => (dispatch, getState) => {
        let { panel } = getState();

        if (isForbidden(isLocked(panel))) {
            return;
        }

        let history = [ ...panel.history ];
        history.pop();
        let active = history[history.length - 1];

        if (active === 'form') {
            vkConnect.send('VKWebAppDisableSwipeBack');
        }

        !isSafari() && dispatch(model.lock());

        dispatch(model.set({
            active,
            history
        }));
    },

    change: (payload) => (dispatch, getState) => {
        let { panel } = getState();
        let target = payload.active;

        if (isForbidden(isLocked(panel), isSame(panel.active, target))) {
            return false;
        }

        !isSafari() && dispatch(model.lock());

        dispatch(model.set({
            active: target,
            history: panel.history
        }));
    },

    set: (payload) => ({
        type: PANEL.SET,
        payload
    }),

    lock: () => ({
        type: PANEL.LOCK
    }),

    unlock: () => ({
        type: PANEL.UNLOCK
    })
};

export default model;
