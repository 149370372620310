import React from 'react';
import classnames from 'classnames';
import Footer from './footer/Footer';
import { Link } from '@vkontakte/vkui';
import Files from './../../../../../../../files/Files';



class Inner extends React.Component {
    render() {
        let { item: { text, files }, isOpen, isHeedCloseContent, onClick, dispatch } = this.props;
        let frameClassName = classnames('discussion-item__frame discussion-item__frame--level-2', {
            'discussion-item__frame--opened': isOpen,
            'discussion-item__frame--closed': isHeedCloseContent,
            'discussion-item__frame--shaded': !isOpen
        });
        let innerClassName = classnames('discussion-item__inner', {
            'pointer': !isOpen
        });

        return (
            <div className={innerClassName} onClick={onClick}>
                <div className={frameClassName}>
                    <div className="discussion-item__body">
                        <div className="discussion-item__content contentable" dangerouslySetInnerHTML={{ __html: text}} />
                        <Files items={files} className="discussion-item__files" downloadable dispatch={dispatch} />
                    </div>
                    <Footer { ...this.props } />
                </div>
                {
                    !isOpen &&
                    <div className="discussion-item__opener-container">
                        <Link style={{fontSize: '14px'}} >Далее</Link>
                    </div>
                }
            </div>
        );
    }
}

export default Inner;
