import React from 'react';



class QuestionSpamPopup extends React.Component {
    render() {
        return (
            <div>Вы слишком часто публикуете вопросы. Попробуйте задать вопрос позже.</div>
        );
    }
}

export default QuestionSpamPopup;
