import React from 'react';
import Rates from './../../../rates/Rates';
import Like from './like/Like';
import Dislike from './dislike/Dislike';
import { getCurrentUserFeedbackState, getCurrentUserFeedback } from './../../utils/utils';



class ClientMyQuestionRater extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isPendingLike: false,
            isPendingDislike: false
        };
    }

    runPendingLike() {
        this.setState({isPendingLike: true});
    };

    runPendingDislike() {
        this.setState({isPendingDislike: true});
    };

    breakPendingLike() {
        this.setState({isPendingLike: false});
    }

    breakPendingDislike() {
        this.setState({isPendingDislike: false});
    }

    runPendingLikeHandler = () => {
        this.runPendingLike();
    };

    runPendingDislikeHandler = () => {
        this.runPendingDislike();
    };

    breakPendingLikeHandler = () => {
        this.breakPendingLike();
    };

    breakPendingDislikeHandler = () => {
        this.breakPendingDislike();
    };

    getMyFeedbackState() {
        let { currentUser, answer_feedbacks } = this.props;
        return getCurrentUserFeedbackState(currentUser.id, answer_feedbacks);
    }

    getFeedback() {
        let { currentUser, answer_feedbacks } = this.props;
        return getCurrentUserFeedback(currentUser.id, answer_feedbacks);
    }

    render() {
        let { likes, dislikes, dispatch, id } = this.props;
        let { isPendingLike, isPendingDislike } = this.state;
        let feedback = this.getFeedback();

        return (
            <Rates
                like={
                    <Like
                        value={likes}
                        state={this.getMyFeedbackState()}
                        isPending={isPendingLike}
                        isDisebled={isPendingDislike}
                        onRunPending={this.runPendingLikeHandler}
                        onBreakPending={this.breakPendingLikeHandler}
                        dispatch={dispatch}
                        entityId={id}
                        feedback={feedback}
                    />
                }
                dislike={
                    <Dislike
                        value={dislikes}
                        state={this.getMyFeedbackState()}
                        isPending={isPendingDislike}
                        isDisebled={isPendingLike}
                        onRunPending={this.runPendingDislikeHandler}
                        onBreakPending={this.breakPendingDislikeHandler}
                        dispatch={dispatch}
                        entityId={id}
                        feedback={feedback}
                    />
                }
            />
        );
    }
}

export default ClientMyQuestionRater;
