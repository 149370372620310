import 'core-js/es6/map';
import 'core-js/es6/set';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import '@vkontakte/vkui/dist/vkui.css';
import vkConnect from './vk-connect/vk-connect';
import vkConnectPromise from '@vkontakte/vkui-connect-promise';
import App from './App';
import store from './store';
import actions from './actions';
import mVKMiniAppsScrollHelper from '@vkontakte/mvk-mini-apps-scroll-helper';
import { isSafari } from '@/browser';
import { setTheme } from './utils/theme';



vkConnect.send('VKWebAppInit');

vkConnectPromise.send('VKWebAppGetUserInfo').then(({data}) => {
    store.dispatch(actions.user.set({data, type: 'vk'}));
    store.dispatch(actions.user.asyncAuthorize());
});

vkConnect.subscribe('VKWebAppUpdateConfig', ({scheme}) => setTheme(scheme));

let root = document.getElementById('root');

mVKMiniAppsScrollHelper(root);

!isSafari() && (window.onpopstate = (e) => {
    let state = e.state || {};
    let panel = state.panel || 'form';
    store.dispatch(actions.panel.change({active: panel}));
});

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    root
);
