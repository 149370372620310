import React from 'react';
import { Gallery, Spinner } from '@vkontakte/vkui';
import './tutorial.css';
import logo from './../../img/logo.svg';
import actions from './../../actions';



class Tutorial extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            slideIndex: 0
        }
    }

    getBackground(backgrounds) {
        backgrounds = backgrounds || {};
        let types = backgrounds.android;
        types = types || {};
        let value = types.xxxhdpi;
        return 'url(' + value + ')';
    }

    replace(text) {
        let result = '';
        for (let i = 0; i < text.length; i++) {
            let cur = text[i];
            let next = text[i + 1];
            if (cur === '\\' && next === 'n') {
                result += '<br>';
                i++;
            } else {
                result += cur;
            }
        }
        return result;
    }

    start() {
        let { dispatch } = this.props;
        dispatch(actions.tutorial.hide());
    }

    clickControlHandler = () => {
        this.start();
    };

    changeHandler = (slideIndex) => {
        this.setState({
            slideIndex
        });
    };

    render() {
        let { loading, items } = this.props;
        let { slideIndex } = this.state;

        if (loading) {
            return (
                <Spinner size="large" />
            );
        }

        return (
            <div className="tutorial">
                <Gallery
                    autoplay={5000}
                    bullets="light"
                    className="tutorial__gallery"
                    slideIndex={slideIndex}
                    onChange={this.changeHandler}>
                    {
                        items.map((item, i) => {
                            let { text, icons: { svg }, backgrounds } = item;
                            let background = this.getBackground(backgrounds);
                            let isLast = i === items.length - 1;
                            let slideStyle = {
                                backgroundImage: background
                            };
                            text = this.replace(text);

                            return (
                                <div key={i} className="tutorial__slide" style={slideStyle}>
                                    <div className="tutorial__body">
                                        <div className="tutorial__logo">
                                            <img src={logo} />
                                        </div>
                                        <div className="tutorial__icon">
                                            <img src={svg} />
                                        </div>
                                        <div className="tutorial__text" dangerouslySetInnerHTML={{__html: text}} />
                                    </div>
                                    <div className="tutorial__footer">
                                        {
                                            isLast ?
                                            <div className="tutorial__control" onClick={this.clickControlHandler}>Начать работу</div> :
                                            <div className="tutorial__control" onClick={this.clickControlHandler}>Пропустить</div>
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </Gallery>
            </div>
        );
    }
}

export default Tutorial;
