import React from 'react';
import File from './File';
import './files.css';
import classnames from 'classnames';
import actions from "./../../actions";



class Files extends React.Component {
    filesize(size) {
        if (size < 1024 * 1024) {
            return `${parseFloat(size / 1024).toFixed(1)} Кб`;
        } else {
            return `${parseFloat(size / (1024 * 1024)).toFixed(1)} Мб`;
        }
    }

    download = (id) => {
        let { dispatch } = this.props;
        dispatch(actions.files.asyncGetFile({id}));
    };

    render() {
        let { items, downloadable, className, dispatch } = this.props;

        if (!items || items.length === 0) {
            return null;
        }

        className = classnames('files-list', {
            [className]: className
        });

        return (
            <ul className={className}>
                {
                    items.map((item) => {
                        let { id, name, size } = item;

                        return (
                            <File
                                key={id}
                                id={id}
                                name={name}
                                size={this.filesize(size)}
                                className="files-list__item"
                                downloadable
                                onClick={this.download}
                            />
                        );
                    })
                }
            </ul>
        );
    }
}

export default Files;
