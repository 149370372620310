import React from 'react';
import classnames from 'classnames';
import Icon24ThumbUp from '@vkontakte/icons/dist/24/thumb_up';
import './rate.css';



class Like extends React.Component {
    render() {
        let { value = 0, onLike, className } = this.props;

        className = classnames('rate-item', {
            [className]: className
        });

        return (
            <div className={className} onClick={onLike}>
                <Icon24ThumbUp fill="#4bb34b" />
                <span>{value}</span>
            </div>
        );
    }
}

export default Like;
