import { TUTORIAL } from './../constants';



let model = {
    show: () => ({
        type: TUTORIAL.SHOW
    }),

    hide: () => ({
        type: TUTORIAL.HIDE
    }),

    set: (payload) => ({
        type: TUTORIAL.SET,
        payload
    })
};

export default model;
