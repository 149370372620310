import { FORM } from './../constants';
import STATUSES from './../statuses';
import update from 'immutability-helper';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';



let initialState = {
    data: {
        question: {
            text: ''
        },
        preset: 'free'
    },
    pending: false
};

let reducer = (state = cloneDeep(initialState), action) => {
    let { type } = action;
    switch (type) {
        case FORM.CLEAR: {
            return update(state, {
                data: {
                    $set: cloneDeep(initialState.data)
                }
            });
        }
        case FORM.CHANGE: {
            let { name, value } = action.payload;
            return update(state, {
                data: {
                    [name]: {
                        $set: value
                    }
                }
            });
        }
        case FORM.SET_DATA: {
            let { data } = action.payload;
            let copy = state.data;
            return update(state, {
                data: {
                    $set: merge(copy, data)
                }
            });
        }
        case FORM.ON_PENDING: {
            return update(state, {
                pending: {
                    $set: true
                }
            });
        }
        case FORM.OFF_PENDING: {
            return update(state, {
                pending: {
                    $set: false
                }
            });
        }
        default: {
            return state;
        }
    }
};

export default reducer;
