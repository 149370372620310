import React from 'react';
import './attached-file.css';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';
import STATUSES from './../../statuses';
import classnames from 'classnames';



let iconSizes = {
    small: 13,
    default: 16
};

class AttachedFile extends React.Component {
    deleteHandler = () => {
        let { id, onDelete } = this.props;
        onDelete && onDelete(id);
    };

    render() {
        let { name, className, status, progress, size } = this.props;
        let progressWidth = status === STATUSES.FILE.ERROR ? 100 : progress;
        let progressStyle = {
            width: progressWidth + '%'
        };

        className = classnames('attached-file', {
            [className]: className,
            [`attached-file--${size}`]: size,
            'attached-file--error': status === STATUSES.FILE.ERROR,
            'attached-file--done': status === STATUSES.FILE.DONE || status === STATUSES.FILE.DELETING
        });

        size = size || 'default';

        let iconSize = iconSizes[size];

        return (
            <li className={className}>
                <div className="attached-file__progress" style={progressStyle}></div>
                <div className="attached-file__inner">
                    <span className="attached-file__name">{name}</span>
                    <div className="attached-file__delete" onClick={this.deleteHandler}>
                        <Icon16Cancel width={iconSize} height={iconSize} fill="rgba(255, 255, 255, 0.8)" />
                    </div>
                </div>
            </li>
        );
    }
}

export default AttachedFile;
