import connect from '@vkontakte/vkui-connect';
import store from './../store';



let subscribers = {};

connect.subscribe((event) => {
    let { type, data } = event.detail;

    subscribers[type] = subscribers[type] || [];
    subscribers[type].forEach((subscriber) => subscriber(data, store.dispatch));
});

export default {
    subscribe: (type, fn) => {
        subscribers[type] = subscribers[type] || [];
        subscribers[type].push(fn);
    },

    send: (type, params = {}) => {
        connect.send(type, params);
    }
};
