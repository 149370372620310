import React from 'react';
import RelativeDate from './../../../../../../../../RelativeDate';



class CommentFooter extends React.Component {
    render() {
        let { item: { timeadd } } = this.props;

        return (
            <div className="discussion-item__footer">
                <div className="discussion-item__footer-left">
                    <div className="discussion-item__meta">
                        <RelativeDate datetime={timeadd} />
                    </div>
                </div>
            </div>
        );
    }
}

export default CommentFooter;
