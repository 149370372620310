import { NOTICE } from './../constants';
import cloneDeep from 'lodash/cloneDeep';
import update from 'immutability-helper';


let initialState = {
    queue: [],
    status: 'waiting'
};

let reducer = (state = initialState, action) => {
    let { type } = action;
    switch (type) {
        case NOTICE.SHIFT: {
            let { queue } = state;

            let copy = cloneDeep(queue);

            copy.shift();

            return update(state, {
                queue: {
                    $set: copy
                }
            });
        }

        case NOTICE.PUSH: {
            let { text } = action.payload;
            let { queue } = state;

            let copy = cloneDeep(queue);

            copy.push({text});

            return update(state, {
                queue: {
                    $set: copy
                }
            });
        }

        case NOTICE.SET_STATUS: {
            let { status } = action.payload;

            return update(state, {
                status: {
                    $set: status
                }
            });
        }

        case NOTICE.CLEAR: {
            let { queue } = state;

            let copy = cloneDeep(queue);
            let first = copy.shift();

            if (first) {
                return update(state, {
                    queue: {
                        $set: [ first ]
                    }
                });
            }

            return state;
        }

        default: {
            return state;
        }
    }
};

export default reducer;
