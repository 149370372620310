export default function(value) {
    let result = '';
    value = value || '';
    for (let i = 0; i < value.length; i++) {
        let symbol = parseInt(value[i], 10);
        if (!isNaN(symbol)) {
            result += symbol;
        }
    }
    return result;
};
