import { MODAL } from './../constants';



let model = {
    open: (payload) => ({
        type: MODAL.OPEN,
        payload
    }),

    close: () => ({
        type: MODAL.CLOSE
    })
};

export default model;
