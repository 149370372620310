import React from 'react';
import NotRateStateDislike from './NotRateStateDislike';
import RatePositiveStateDislike from './RatePositiveStateDislike';
import RateNegativeStateDislike from './RateNegativeStateDislike';



let components = {
    not_rate: NotRateStateDislike,
    rate_positive: RatePositiveStateDislike,
    rate_negative: RateNegativeStateDislike
};

class Dislike extends React.Component {
    render() {
        let { state } = this.props;
        let Component = components[state];

        if (!Component) {
            return null;
        }

        return (
            <Component { ...this.props } />
        );
    }
}

export default Dislike;
