import React from 'react';
import { ModalRoot, ModalPage, ModalPageHeader } from '@vkontakte/vkui';
import MODALS from './../../modals';
import SubmitPhoneSuggestionModal from './modals/submit-phone-suggestion/SubmitPhoneSuggestionModal';
import actions from './../../actions';



let components = {
    [MODALS.SUBMIT_PHONE_SUGGESTION]: SubmitPhoneSuggestionModal
};

class Modal extends React.Component {
    closeHandler = () => {
        let { modal: { onClose } } = this.props;
        onClose && onClose();
    };

    render() {
        let { modal, dispatch, user } = this.props;

        return (
            <ModalRoot activeModal={modal.name}>
                {
                    Object.keys(components).map((name) => {
                        let Component = components[name];

                        if (!Component) {
                            return null;
                        }

                        return (
                            <ModalPage
                                key={name}
                                id={name}
                                onClose={this.closeHandler}
                                settlingHeight={modal.height ? modal.height : 75}
                                header={
                                    <ModalPageHeader>
                                        <div style={{fontSize: '20px', lineHeight: '24px'}}>
                                            {modal.title}
                                        </div>
                                    </ModalPageHeader>
                                }>
                                <Component { ...modal.data } user={user} dispatch={dispatch} />
                            </ModalPage>
                        );
                    })
                }
            </ModalRoot>
        );
    }
}

export default Modal;
