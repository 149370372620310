import host from './../http/host';
import { Request } from '@/jrequester';
import Maybe from '@/monads/maybe';
import url from './../helpers/url';



export default {
    getSelfList: (payload) => {
        let { token, limit, offset } = payload;

        let params = {limit, offset};

        let request = new Request({
            method: 'POST',
            url: host.get() + '/rest/questions/self/' + url.query(params),
            withCredentials: true,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        request
            .response()
            .OK()
            .map((response) => (
                Maybe
                    .from(response)
                    .filter((response) => response.meta.code === 200)
                    .map((response) => response.data)
                    .map((data) => data.questions)
            ));

        return request;
    }
};
