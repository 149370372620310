import file from './file';
import user from './user';
import questions from './questions';
import question from './question';
import tutorial from './tutorial';



export default {
    file,
    user,
    questions,
    question,
    tutorial
};
