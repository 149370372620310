import { QUESTION } from './../constants';
import STATUSES from './../statuses';
import POPUPS from './../popups';
import actions from './index';
import ServerListener from './../helpers/server-listener';
import qs from 'qs';
import { getToken } from './../utils/token';
import {
    addQuestionComment,
    getQuestionAnswers,
    getQuestionComments,
    getQuestion,
    rateQuestionAnswer
} from './../fetchers';



function getIdFromUrl() {
    let search = window.location.search.substr(1);
    let params = qs.parse(search) || {};
    let id = parseInt(params.question_id, 10);
    return id || null;
}

function getQuestionByID(id, questions) {
    let filteredQuestions = questions.filter((question) => parseInt(question.id, 10) === parseInt(id, 10));
    return filteredQuestions.length > 0 ? filteredQuestions[0] : null;
}

function getFiles(state, namespace) {
    let ids = [];
    let files = state.files[namespace];
    files = files || [];
    files.forEach((file) => {
        ids.push(file.id);
    });
    return ids;
}

function checkLoadingFiles(files) {
    files = files || [];
    files = files.filter((file) => file.status === STATUSES.FILE.LOADING);
    return files.length > 0;
}

let listeners = [];

let requests = {};

let batch = (payload, dispatch, getState) => () => {
    let getAnswersRequest = getQuestionAnswers(payload);

    getAnswersRequest
        .response()
        .OK()
        .subscribe((data) => {
            data.map(({answers, tree}) => {
                let state = getState();

                answers = Object.keys(answers).reduce((total, key) => {
                    let answer = answers[key];
                    if (!state.question.answers[answer.id]) {
                        total[answer.id] = {
                            ...answer,
                            state: 'new'
                        };
                    }
                    return total;
                }, {});

                if (Object.keys(answers).length > 0) {
                    dispatch(actions.buffer.write({
                        key: payload.data.id,
                        data: {
                            answers,
                            tree
                        }
                    }));
                }
            });
        });

    return getAnswersRequest;
};

let rateAnswer = (data, state, {failed}) => (
    rateQuestionAnswer({
        token: getToken(state.user.vk.id)(),
        data
    })
        .failed(() => failed && failed())
        .response()
);

let model = {
    destroy: () => (dispatch) => {
        dispatch(model.select({question: null}));
        dispatch(actions.loading.break(['details', 'discussion']));
        dispatch(actions.buffer.clear());
        Object.keys((requests)).forEach((key) => requests[key].abort());
        listeners.forEach((listener) => listener.break());
        listeners = [];
    },

    asyncSelect: (data = {}) => (dispatch, getState) => {
        let state = getState();

        let { id } = data;
        let { user, questions } = state;

        let getTokenByVKUserID = getToken(user.vk.id);

        id = id || getIdFromUrl();

        if (!id) {
            dispatch(actions.panel.change('form'));
            return;
        }

        let question = getQuestionByID(id, questions);

        let select = (question) => {
            dispatch(actions.loading.run(['details', 'discussion']));
            dispatch(model.select({question}));

            let getAnswersRequest = getQuestionAnswers({
                token: getTokenByVKUserID(),
                data: {
                    id
                }
            });

            getAnswersRequest
                .response()
                .OK()
                .subscribe((data) => {
                    data.map(({answers}) => {
                        delete requests['GetAnswers'];

                        let getCommentsRequest = getQuestionComments({
                            token: getTokenByVKUserID(),
                            data: {
                                id
                            }
                        });

                        getCommentsRequest
                            .response()
                            .OK()
                            .subscribe((data) => {
                                data.map(({comments, tree}) => {
                                    delete requests['GetComments'];

                                    dispatch(model.update({
                                        data: {
                                            answers,
                                            comments,
                                            current: {
                                                ...question.current,
                                                discussion_tree: [ ...tree ]
                                            }
                                        }
                                    }));

                                    dispatch(actions.loading.break(['details', 'discussion']));

                                    let listener = new ServerListener(batch({
                                        token: getTokenByVKUserID(),
                                        data: {
                                            id
                                        }
                                    }, dispatch, getState));

                                    listener.run();
                                    listeners.push(listener);
                                });
                            });

                        requests['GetComments'] = getCommentsRequest;
                    });
                });

            requests['GetAnswers'] = getAnswersRequest;
        };

        if (question) {
            select(question);
        } else {
            dispatch(actions.loading.run(['question']));

            let getQuestionRequest = getQuestion({
                token: getTokenByVKUserID(),
                data: {
                    id
                }
            });

            getQuestionRequest
                .response()
                .OK()
                .subscribe((question) => {
                    delete requests['GetQuestion'];

                    question.map((question) => {
                        dispatch(actions.loading.break(['question']));
                        select(question);
                    });
                });

            requests['GetQuestion'] = getQuestionRequest;
        }
    },

    asyncAddComment: ({data, namespace}, success, error, reject) => (dispatch, getState) => {
        let state = getState();
        let files = getFiles(state, namespace);

        let { question, questions, user } = state;

        let getTokenByVKUserID = getToken(user.vk.id);

        data.files = files;
        data.question_id = question.current.id;

        if (checkLoadingFiles(state.files[namespace])) {
            let alert = {
                title: 'Не все файлы загружены',
                name: POPUPS.NOT_ALL_FILES_UPLOADED,
                actions: [{
                    title: 'OK',
                    autoclose: true
                }]
            };
            dispatch(actions.alert.open({data: alert}));
            reject && reject();
            return;
        }

        addQuestionComment({
            token: getTokenByVKUserID(),
            data
        })
            .failed(() => reject && reject())
            .response()
            .OK()
            .subscribe((entity) => {
                entity.map((entity) => {
                    let tree = question.current.discussion_tree || [];

                    dispatch(actions.files.clear({namespace}));

                    dispatch(model.update({
                        data: {
                            current: {
                                ...question.current,
                                discussion_tree: [
                                    ...tree,
                                    {
                                        entity_id: entity.id,
                                        entity_type: 'qcomment',
                                        id: 'qcomment_' + entity.id,
                                        parent_entity_id: entity.answer_id,
                                        parent_entity_type: null,
                                        question_id: question.current.id,
                                        timeadded: entity.timeadd
                                    }
                                ]
                            }
                        }
                    }));

                    dispatch(model.addComments({items: {[entity.id]: entity}}));

                    success && success(entity);
                });
            });
    },

    asyncAnswerLike: (data, {success, reject}) => (dispatch, getState) => {
        let state = getState();

        rateAnswer({
            entity: 'answer',
            entity_id: data.id,
            is_positive: 1
        }, state, {failed: reject})
            .OK()
            .subscribe((wrap) => {
                wrap.map((id) => {
                    dispatch(model.answerLike({
                        id: data.id,
                        feedback: {
                            id,
                            is_positive: 1,
                            user: state.user.prvd
                        }
                    }));

                    success && success();
                });
            });
    },

    asyncAnswerDislike: (data, {success, reject}) => (dispatch, getState) => {
        let state = getState();

        rateAnswer({
            entity: 'answer',
            entity_id: data.id,
            is_positive: 0
        }, state, {failed: reject})
            .OK()
            .subscribe((wrap) => {
                wrap.map((id) => {
                    dispatch(model.answerDislike({
                        id: data.id,
                        feedback: {
                            id,
                            is_positive: 0,
                            user: state.user.prvd
                        }
                    }));

                    success && success();
                });
            });
    },

    asyncAnswerRedislike: (data, {success, reject}) => (dispatch, getState) => {
        let state = getState();

        rateAnswer({
            entity: 'answer',
            entity_id: data.id,
            is_positive: 0
        }, state, {failed: reject})
            .OK()
            .subscribe((wrap) => {
                wrap.map((id) => {
                    dispatch(model.answerRedislike({
                        id: data.id,
                        feedback: {
                            id: data.feedback.id
                        }
                    }));

                    success && success();
                });
            });
    },

    answerDislike: (payload) => ({
        type: QUESTION.ANSWER_DISLIKE,
        payload
    }),

    answerLike: (payload) => ({
        type: QUESTION.ANSWER_LIKE,
        payload
    }),

    answerRedislike: (payload) => ({
        type: QUESTION.ANSWER_REDISLIKE,
        payload
    }),

    update: (payload) => ({
        type: QUESTION.UPDATE,
        payload
    }),

    select: (payload) => ({
        type: QUESTION.SELECT,
        payload
    }),

    addComments: (payload) => ({
        type: QUESTION.ADD_COMMENTS,
        payload
    }),

    addAnswers: (payload) => ({
        type: QUESTION.ADD_ANSWERS,
        payload
    }),

    updateAnswers: (payload) => ({
        type: QUESTION.UPDATE_ANSWERS,
        payload
    })
};

export default model;
