import host from './../http/host';
import { Request } from '@/jrequester';
import Maybe from '@/monads/maybe';



export default {
    delete: (payload) => {
        let { token, data } = payload;

        let request = new Request({
            method: 'DELETE',
            url: host.get() + '/rest/upload/' + data.id + '/',
            withCredentials: true,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        return request;
    },

    upload: (payload) => {
        let { token, data: { file, namespace } } = payload;

        let request = new Request({
            method: 'POST',
            url: host.get() + '/rest/upload/',
            withCredentials: true,
            responseType: 'json',
            headers: {
                Authorization: 'Bearer ' + token
            },
            data: {
                file_upload_id: file.id,
                file_namespace: namespace,
                file
            },
        });

        request
            .response()
            .OK()
            .map((response) => (
                Maybe
                    .from(response)
                    .filter((response) => response.meta.code === 200)
                    .map((response) => response.data)
                    .map((data) => data.entity_id)
            ));

        request
            .upload()
            .progress()
            .map((event) => ({
                id: file.id,
                value: Math.round((event.loaded / event.total) * 100),
                namespace
            }));

        return request;
    },

    get: (payload) => {
        let { token, data } = payload;

        let request = new Request({
            method: 'GET',
            url: host.get() + '/rest/files/' + data.id + '/',
            withCredentials: true,
            responseType: 'blob',
            headers: {
                Authorization: 'Bearer ' + token
            }
        });

        return request;
    },

};
