import React from 'react';
import classnames from 'classnames';
import Icon24Document from '@vkontakte/icons/dist/24/document';
import AttachedFiles from './../../../../attached-files/AttachedFiles';
import { Textarea, Button, Spinner, File } from '@vkontakte/vkui';



const MIN_TEXT_LENGTH = 1;

class DetailForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '',
            isPending: false
        };
    }

    changeHandler = (e) => {
        let { value } = e.target;
        this.setState({
            text: value
        });
    };

    submitHandler = () => {
        if (!this.isValid()) {
            return;
        }
        if (this.isPending()) {
            return;
        }
        this.setState({
            isPending: true
        });
        let { onSubmit } = this.props;
        let { text } = this.state;
        text = text.trim();
        onSubmit && onSubmit({text}, {
            reject: () => {
                this.setState({
                    isPending: false
                });
            }
        });
    };

    isValid() {
        let { text } = this.state;
        return text.trim().length >= MIN_TEXT_LENGTH;
    }

    isPending() {
        let { isPending } = this.state;
        return isPending;
    }

    render() {
        let { onAddFiles, files, namespace, dispatch } = this.props;
        let { text } = this.state;

        return (
            <div className="detail-form">
                <div className="detail-form__title">Уточнение к вопросу</div>
                <div className="detail-form__textarea textarea">
                    <textarea
                        value={text}
                        onChange={this.changeHandler}>
                    </textarea>
                </div>
                <AttachedFiles files={files} namespace={namespace} dispatch={dispatch} className="detail-form__files" />
                <File
                    multiple
                    className="detail-form__file pointer"
                    before={<Icon24Document />}
                    size="xl" level="2"
                    children="Добавить файлы"
                    onChange={onAddFiles}
                    accept=".png, .jpg, .jpeg, .jpe, .gif, .bmp, .tif, .tiff,
                    .txt, .rtf, .csv, .xml, .doc, .docx, .odt, .xls,
                    .xlsx, .odf, .ods, .pps, .ppt, .pptx, .pdf, .epub,
                    .odf, .ods, .zip, .rar"
                />
                <Button
                    disabled={!this.isValid() || this.isPending()}
                    onClick={this.submitHandler}
                    className="detail-form__submit pointer"
                    size="xl"
                    level="commerce">
                    {
                        this.isPending() ?
                        <Spinner size="small" className="detail-form__loader" /> :
                        'Отправить'
                    }
                </Button>
            </div>
        );
    }
}

export default DetailForm;
