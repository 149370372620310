import React from 'react';
import Icon24Attach from '@vkontakte/icons/dist/24/attach';
import './file.css';
import classnames from 'classnames';
import Icon24Download from '@vkontakte/icons/dist/24/download';



class File extends React.Component {

    clickHandler = () => {
        let { id, onClick } = this.props;
        onClick && onClick(id);
    };

    render() {
        let { name, downloadable, uri, size, className } = this.props;

        className = classnames('file-list-item', {
            [className]: className
        });

        return (
            <li className={className}>
                <div className="file-list-item__inner">
                    <div className="file-list-item__chunk">
                        <div className="file-list-item__icon">
                            <Icon24Attach fill="#cacaca" />
                        </div>
                        <div className="file-list-item__name">{name}</div>
                    </div>
                    <div className="file-list-item__chunk">
                        <div className="file-list-item__size">{size}</div>
                        {
                            downloadable &&
                            <Icon24Download fill="#cacaca" />
                        }
                    </div>
                </div>
                {
                    downloadable &&
                    <a target="_blank" class="file-list-item__link" onClick={this.clickHandler}></a>
                }
            </li>
        );
    }
}

export default File;
