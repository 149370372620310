import { BUFFER } from './../constants';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';



let reducer = (state = {}, action) => {
    let { type } = action;
    switch (type) {
        case BUFFER.WRITE: {
            let { key, data } = action.payload;
            let copy = cloneDeep(state);
            copy[key] = copy[key] || {};
            merge(copy[key], data);
            return copy;
        }
        case BUFFER.CLEAR: {
            return {};
        }
        default: {
            return state;
        }
    }
};

export default reducer;
