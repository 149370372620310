import React from 'react';
import './not-all-files-uploaded-popup.css';



class NotAllFilesUploadedPopup extends React.Component {
    render() {
        return (
            <div className="not-all-files-uploaded-popup">Пожалуйста, дождитесь загрузки всех файлов</div>
        );
    }
}

export default NotAllFilesUploadedPopup;
