var requestAnimationFrame = (
    window.requestAnimationFrame ||
    window.msRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame
);

window.requestAnimationFrame = requestAnimationFrame;

export default function animate(options) {
    var start = performance.now();
    
    window.requestAnimationFrame(function animate(time) {
        var timeFraction = (time - start) / options.duration;

        if (timeFraction > 1) timeFraction = 1;

        var progress = options.timing(timeFraction)

        options.draw(progress);

        if (timeFraction < 1) {
            window.requestAnimationFrame(animate);
        } else {
            options.done && options.done();
        }
    });
}
