let { localStorage } = window;



export default {
    get: (id) => {
        let tokens = localStorage.getItem('tokens');
        if (!tokens) {
            return null;
        }
        tokens = JSON.parse(tokens);
        let token = tokens[id];
        return token ? token : null;
    },
    set: (id, token) => {
        let tokens = localStorage.getItem('tokens');
        if (!tokens) {
            tokens = '{}';
        }
        tokens = JSON.parse(tokens);
        tokens[id] = token;
        tokens = JSON.stringify(tokens);
        localStorage.setItem('tokens', tokens);
    }
};
