import React from 'react';
import './invalidated-files-popup.css';
import pluralize from './../../../../helpers/pluralize';
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';



class InvalidatedFilesPopup extends React.PureComponent {
    getSectionFilesByType() {
        let { invalidatedFiles: { byType } } = this.props;
        let deniedExtensions = {};
        byType = byType || [];
        byType.forEach((file) => {
            deniedExtensions[file.extension] = true;
        });
        deniedExtensions = Object.keys(deniedExtensions);

        if (byType.length === 0) {
            return null;
        }

        return (
            <div className="invalidated-files-popup__section">
                <div className="invalidated-files-popup__titles">
                    <div>Загрузка файлов с {pluralize(deniedExtensions.length, ['расширением', 'расширениями', 'расширениями'])} <span style={{fontWeight: 'bold'}}>{deniedExtensions.join(', ')}</span> запрещена</div>
                    {
                        byType.length > 1 ?
                        <div>Следующие файлы не будут загружены:</div> :
                        <div>Следующий файл не будет загружен:</div>
                    }
                </div>
                <ul>
                    {
                        byType.map((file, i) => {
                            let { name } = file;
                            return (
                                <li key={i}>
                                    <Icon16Cancel width="12" height="12" className="invalidated-files-popup__cross" fill="#e64646" /><span>{name}</span>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        );
    }

    getSectionFilesBySyze() {
        let { invalidatedFiles: { bySize }, config: { maxFileSize } } = this.props;
        bySize = bySize || [];

        if (bySize.length === 0) {
            return null;
        }

        return (
            <div className="invalidated-files-popup__section">
                <div className="invalidated-files-popup__titles">
                    <div>Максимальный вес файла <strong>{maxFileSize / 1024 /1024} МБ</strong></div>
                    {
                        bySize.length > 1 ?
                        <div>Следующие файлы не будут загружены:</div> :
                        <div>Следующий файл не будет загружен:</div>
                    }
                </div>
                <ul>
                    {
                        bySize.map((file, i) => {
                            let { name } = file;
                            return (
                                <li key={i}>
                                    <Icon16Cancel width="12" height="12" className="invalidated-files-popup__cross" fill="#e64646" /><span>{name}</span>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>
        );
    }

    getSectionExtraFiles() {
        let { extraFiles, isMaxFilesCountReached, config: { maxFilesCount } } = this.props;
        extraFiles = extraFiles || [];

        if (!isMaxFilesCountReached) {
            return null;
        }

        return (
            <div className="invalidated-files-popup__section">
                {
                    extraFiles.length > 0 ?
                    <div className="invalidated-files-popup__titles">
                        <div>Максимальное количество загружаемых файлов достигнуто - <strong>{maxFilesCount}</strong></div>
                        {
                            extraFiles.length > 1 ?
                            <div>Следующие файлы не будут загружены:</div> :
                            <div>Следующий файл не будет загружен:</div>
                        }
                    </div> :
                    <div className="invalidated-files-popup__titles">
                        <div>Максимальное количество загружаемых файлов достигнуто - <strong>{maxFilesCount}</strong></div>
                    </div>
                }
                {
                    extraFiles.length > 0 &&
                    <ul>
                        {
                            extraFiles.map((file, i) => {
                                let { name } = file;
                                return (
                                    <li key={i}>
                                        <Icon16Cancel width="12" height="12" className="invalidated-files-popup__cross" fill="#e64646" /><span>{name}</span>
                                    </li>
                                );
                            })
                        }
                    </ul>
                }
            </div>
        );
    }

    render() {
        return (
            <div className="invalidated-files-popup">
                {this.getSectionFilesByType()}
                {this.getSectionFilesBySyze()}
                {this.getSectionExtraFiles()}
            </div>
        );
    }
}

export default InvalidatedFilesPopup;
