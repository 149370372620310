import objectToFormData from 'object-to-formdata';
import merge from 'lodash/merge';



const Statuses = {
    OK: 200,
    Unauthorized: 401,
    Forbidden: 403,
    TooManyRequests: 429
};

class Upload {
    _chain = null;
    _data = null;
    _mappers = {};
    _subscribers = {};
    _event = null;
    _links = {};

    resolve(event, data) {
        this._event = event;
        this._data = data;

        this._runMappers();
        this._runSubscribers();
    }

    _runMappers() {
        let mappers = this._mappers[this._event] || [];
        mappers.forEach((mapper) => {
            this._data = mapper(this._data);
        });
    }

    _runSubscribers() {
        let subscribers = this._subscribers[this._event] || [];
        subscribers.forEach((subscriber) => subscriber(this._data));
    }

    chain() {
        if (!this._chain) {
            this._chain = new UploadChain(this);
        }
        return this._chain;
    }

    _linked(event) {
        if (!this._links[event]) {
            this._mappers[event] = this._mappers[event] || [];
            this._subscribers[event] = this._subscribers[event] || [];

            let mappers = this._mappers[event];
            let subscribers = this._subscribers[event];

            let map = (mappers) => function(fn) {
                mappers.push(fn);
                return this;
            };

            let subscribe = (subscribers, chain) => (fn) => {
                subscribers.push(fn);
                return chain;
            };

            this._links[event] = {
                map: map(mappers),
                subscribe: subscribe(subscribers, this._chain)
            };
        }

        return this._links[event];
    }
}

class UploadChain {
    _context = null;

    constructor(context) {
        this._context = context;
    }

    progress() {
        return this._context._linked('progress');
    }
}

class Response {
    _chain = null;
    _data = null;
    _status = null;
    _mappers = {};
    _subscribers = {};
    _links = {};

    resolve(status, data) {
        this._status = status;
        this._data = data;

        this._runMappers();
        this._runSubscribers();
    }

    _runMappers() {
        let mappers = this._mappers[this._status] || [];
        mappers.forEach((mapper) => {
            this._data = mapper(this._data);
        });
    }

    _runSubscribers() {
        let subscribers = this._subscribers[this._status] || [];
        subscribers.forEach((subscriber) => subscriber(this._data));
    }

    chain() {
        if (!this._chain) {
            this._chain = new ResponseChain(this);
        }
        return this._chain;
    }

    _linked(code) {
        if (!this._links[code]) {
            this._mappers[code] = this._mappers[code] || [];
            this._subscribers[code] = this._subscribers[code] || [];

            let mappers = this._mappers[code];
            let subscribers = this._subscribers[code];

            let map = (mappers) => function(fn) {
                mappers.push(fn);
                return this;
            };

            let subscribe = (subscribers, chain) => (fn) => {
                subscribers.push(fn);
                return chain;
            };

            this._links[code] = {
                map: map(mappers),
                subscribe: subscribe(subscribers, this._chain)
            };
        }

        return this._links[code];
    }
}

class ResponseChain {
    _context = null;

    constructor(context) {
        this._context = context;
    }

    OK() {
        return this._context._linked(Statuses.OK);
    }

    Unauthorized(fn) {
        return this._context._linked(Statuses.Unauthorized);
    }

    Forbidden(fn) {
        return this._context._linked(Statuses.Forbidden);
    }

    TooManyRequests(fn) {
        return this._context._linked(Statuses.TooManyRequests);
    }
}

class Fetcher {
    _setHeaders(xhr, headers) {
        Object.keys(headers).forEach((key) => xhr.setRequestHeader(key, headers[key]));
    }

    fetch(settings, {load, error, upload}) {
        let
            {
                method,
                url,
                body,
                headers = {},
                withCredentials,
                responseType,
            } = settings;

        let xhr = new XMLHttpRequest();

        xhr.open(method, url);

        this._setHeaders(xhr, headers);

        xhr.withCredentials = withCredentials;
        xhr.responseType = responseType;

        xhr.upload.onprogress = (event) => {
            upload.progress && upload.progress(event);
        };

        xhr.send(body);

        xhr.onload = () => {
            load && load(xhr);
        };

        xhr.onerror = () => {
            error && error(xhr);
        };

        return xhr;
    }
}

export class Request {
    _settings = {};
    _config = {};
    _xhr = null;
    _fetcher = null;
    _response = null;
    _upload = null;

    _runCount = 0;

    _failedHandlers = [];
    _bodyFormatters = {};

    constructor(settings, config = {}) {
        this._config = config;
        this._settings = settings;

        this._fetcher = new Fetcher();
        this._response = new Response();
        this._upload = new Upload();

        this._createBodyFormatters();
    }

    _createBodyFormatters() {
        this._bodyFormatters['application/json'] = this._toJSON;
        this._bodyFormatters['multipart/form-data'] = this._toFormData;
    }

    _toJSON(data) {
        return JSON.stringify(data);
    }

    _toFormData(data) {
        return objectToFormData(data);
    }

    _configureParams() {
        let
            {
                method,
                data = {},
                url,
                withCredentials = false,
                responseType = '',
                dataType,
                headers
            } = this._settings;

        let body = this._getBody(data, dataType);

        return {
            url,
            method,
            body,
            headers,
            responseType,
            withCredentials
        };
    }

    _getBody(data, type) {
        let formatter = this._bodyFormatters[type] || this._bodyFormatters['multipart/form-data'];
        return formatter(data);
    }

    _isCanRun() {
        let { retries = Infinity } = this._config;
        return this._runCount < retries;
    }

    _fetch() {
        this._runCount++;

        this._xhr = this._fetcher.fetch(
            this._configureParams(),
            {
                load: (xhr) => {
                    let { status, response } = xhr;
                    this._response.resolve(status, response);
                },
                error: (xhr) => {
                    this._failedHandlers.forEach((handler) => handler(xhr));
                },
                upload: {
                    progress: (event) => {
                        this._upload.resolve('progress', event);
                    }
                }
            }
        );
    }

    configure(config = {}) {
        this._config = config;
        return this;
    }

    run(prerunner) {
        if (!this._isCanRun()) {
            return this;
        }

        if (prerunner) {
            prerunner(() => this._fetch())
        } else {
            this._fetch();
        }

        return this;
    }

    mutate(settings) {
        this._settings = merge(this._settings, settings);
        return this;
    }

    abort() {
        this._xhr && this._xhr.abort();
        return this;
    }

    failed(fn) {
        this._failedHandlers.push(fn);
        return this;
    }

    upload() {
        return this._upload.chain();
    }

    response() {
        return this._response.chain();
    }
}
