import { FILES } from './../constants';
import STATUSES from './../statuses';
import update from 'immutability-helper';



function updateFile(id, files, data) {
    files = files || [];
    let updatedFiles = [];
    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let isTargetFile = file.id === id;
        if (isTargetFile) {
            let targetPropsObj = {};
            Object.keys(data).forEach((prop) => {
                let value = data[prop];
                targetPropsObj[prop] = {
                    $set: value
                };
            });
            let updatedFile = update(file, targetPropsObj);
            updatedFiles.push(updatedFile);
        } else {
            updatedFiles.push(file);
        }
    }
    return updatedFiles;
}

let reducer = (state = {}, action) => {
    let { type } = action;
    switch (type) {
        case FILES.ADD: {
            let { files, namespace } = action.payload;
            let currents = state[namespace];
            currents = currents || [];
            let merged = [ ...currents ];
            files.forEach((file) => {
                let isNew = true;
                for (let i = 0; i < currents.length; i++) {
                    if (currents[i].id === file.id) {
                        isNew = false;
                        break;
                    }
                }
                isNew && merged.push(file);
            });
            return update(state, {
                [namespace]: {
                    $set: merged
                }
            });
        }
        case FILES.UPDATE_FILE_ID: {
            let { currentId, newId, namespace } = action.payload;
            return update(state, {
                [namespace]: {
                    $set: updateFile(currentId, state[namespace], {id: newId})
                }
            });
        }
        case FILES.PROGRESS_FILE: {
            let { id, value, namespace } = action.payload;
            return update(state, {
                [namespace]: {
                    $set: updateFile(id, state[namespace], {progress: value})
                }
            });
        }
        case FILES.LOADING_FILE: {
            let { id, namespace } = action.payload;
            return update(state, {
                [namespace]: {
                    $set: updateFile(id, state[namespace], {status: STATUSES.FILE.LOADING})
                }
            });
        }
        case FILES.DELETING_FILE: {
            let { id, namespace } = action.payload;
            return update(state, {
                [namespace]: {
                    $set: updateFile(id, state[namespace], {status: STATUSES.FILE.DELETING})
                }
            });
        }
        case FILES.DONE_FILE: {
            let { id, namespace } = action.payload;
            return update(state, {
                [namespace]: {
                    $set: updateFile(id, state[namespace], {
                        status: STATUSES.FILE.DONE,
                        progress: 100
                    })
                }
            });
        }
        case FILES.DELETE_FILE: {
            let { id, namespace } = action.payload;
            let files = state[namespace];
            files = files || [];
            let filteredFiles = files.filter((file) => file.id !== id);
            return update(state, {
                [namespace]: {
                    $set: filteredFiles
                }
            });
        }
        case FILES.ERROR_FILE: {
            let { id, namespace } = action.payload;
            return update(state, {
                [namespace]: {
                    $set: updateFile(id, state[namespace], {status: STATUSES.FILE.ERROR})
                }
            });
        }
        case FILES.CLEAR: {
            let { namespace } = action.payload;
            return update(state, {
                [namespace]: {
                    $set: []
                }
            });
        }
        default: {
            return state;
        }
    }
};

export default reducer;
