import MobileDetect from 'mobile-detect';



const md = new MobileDetect(window.navigator.userAgent);



export let isSafari = () => md.userAgent() === 'Safari';

export let browser = {
    isSafari
};
