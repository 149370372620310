import React from 'react';
import Item from './../../Item';
import classnames from 'classnames';
import { Cell, Avatar, Link } from '@vkontakte/vkui';
import Icon24CommentOutline from '@vkontakte/icons/dist/24/comment_outline';
import CommentForm from './../comment-form/CommentForm';
import actions from './../../../../../../../actions';
import RelativeDate from './../../../../../../RelativeDate';
import { scroller } from 'react-scroll';
import Rater from './../../../rater/Rater';
import Files from './../../../../../../files/Files';
import host from './../../../../../../../http/host';



const MIN_FRAME_HEIGHT = 70;
const MIN_FRAME_HEIGHT_AMENDMENT = 50;

class Root extends React.Component {
    contentHeight = 0;

    constructor(props) {
        super(props);

        this.checkContentHeight(props.item.text);

        this.state = {
            isShowCommentForm: false,
            isOpen: !this.isHeedCloseContent()
        };
    }

    componentDidUpdate(prevProps, prevState) {
        let { isShowCommentForm: isShowCommentFormPrev } = prevState;
        let { isShowCommentForm: isShowCommentFormCurr } = this.state;
        let isFormClosed = isShowCommentFormPrev && !isShowCommentFormCurr;
        isFormClosed && this.deleteAllFiles();
    }

    deleteAllFiles() {
        let { dispatch } = this.props;
        dispatch(actions.files.asyncDeleteAll({namespace: this.namespace()}));
    }

    namespace() {
        let { item: { id } } = this.props;
        return 'parent_id_' + id;
    }

    checkContentHeight(text) {
        let tmp = document.createElement('div');
        tmp.classList.add('discussion-item__content');
        tmp.classList.add('discussion-item__content--tmp');
        tmp.innerHTML = text;
        document.body.appendChild(tmp);
        this.contentHeight = parseFloat(getComputedStyle(tmp).height, 10);
        document.body.removeChild(tmp);
    }

    isHeedCloseContent() {
        return this.contentHeight > MIN_FRAME_HEIGHT + MIN_FRAME_HEIGHT_AMENDMENT;
    }

    toggleCommentForm() {
        let { isShowCommentForm } = this.state;
        this.setState({
            isShowCommentForm: !isShowCommentForm
        });
    }

    getName() {
        let { item: { user } } = this.props;
        user = user || {};
        let { name, surname } = user;
        let result = '';
        if (name && surname) {
            result += name + ' ' + surname;
        } else {
            if (name) {
                result += name;
            } else if (surname) {
                result += surname;
            }
        }
        return result;
    }

    getDescription() {
        let specialization = this.getSpecialization();
        let city = this.getCity();
        let result = '';
        if (specialization && city) {
            result += specialization + ', ' + city;
        } else {
            if (specialization) {
                result += specialization;
            } else if (city) {
                result += city;
            }
        }
        return result;
    }

    getSpecialization() {
        let { item: { user } } = this.props;
        user = user || {};
        let { specialization_name } = user;
        return specialization_name || '';
    }

    getCity() {
        let { item: { user } } = this.props;
        user = user || {};
        let { city_label, city_name } = user;
        let result = '';
        if (city_label && city_name) {
            result += city_label + '. ' + city_name;
        } else {
            if (city_name) {
                result += city_name;
            }
        }
        return result;
    }

    submitCommentHandler = ({text}, callbacks) => {
        let { dispatch, item } = this.props;
        let { reject } = callbacks;
        let namespace = this.namespace();
        dispatch(actions.question.asyncAddComment({
            namespace,
            data: {
                text,
                parent_entity_type: 'answer',
                parent_entity_id: item.id
            }
        }, (entity) => {
            this.setState({
                isShowCommentForm: false
            });
            setTimeout(() => {
                scroller.scrollTo('qcomment-' + entity.id, {
                    duration: 800,
                    delay: 0,
                    offset: -70,
                    smooth: 'easeInOutQuart'
                });
            });
        }, () => {}, () => {
            reject && reject();
        }));
    };

    addCommentClickHandler = () => {
        this.toggleCommentForm();
    };

    openClickHandler = () => {
        this.setState({
            isOpen: true
        })
    };

    render() {
        let { item: { children, user, text, timeadd, files, feedbacks_count_positive, feedbacks_count_negative, id, question_id, answer_feedbacks }, currentUser, dispatch } = this.props;
        let { isShowCommentForm, isOpen } = this.state;
        let frameClassName = classnames('discussion-item__frame', {
            'discussion-item__frame--opened': isOpen,
            'discussion-item__frame--closed': this.isHeedCloseContent(),
            'discussion-item__frame--shaded': !isOpen
        });
        let innerClassName = classnames('discussion-item__inner', {
            'pointer': !isOpen
        });

        user = user || {};

        let namespace = this.namespace();

        return (
            <React.Fragment>
                <div className="discussion-item__main discussion-item__main--highlighted">
                    <div className="discussion-item__header">
                        <Cell
                            description={this.getDescription()}
                            before={<Avatar src={user.avatar} />}
                        >
                            {this.getName()}
                        </Cell>
                    </div>
                    <div className={innerClassName} onClick={this.openClickHandler}>
                        <div className={frameClassName}>
                            <div className="discussion-item__body">
                                <div className="discussion-item__content contentable" dangerouslySetInnerHTML={{ __html: text}} />
                                <Files items={files} className="discussion-item__files" downloadable dispatch={dispatch} />
                            </div>
                            <div className="discussion-item__footer">
                                <div className="discussion-item__footer-left">
                                    <div className="discussion-item__meta">
                                        <RelativeDate datetime={timeadd} />
                                    </div>
                                    <div className="discussion-item__reply pointer" onClick={this.addCommentClickHandler}>
                                        <Icon24CommentOutline fill="#a7a7a7" />
                                    </div>
                                </div>
                                <div className="discussion-item__footer-right">
                                    <Rater
                                        utensils="my"
                                        role="client"
                                        likes={feedbacks_count_positive}
                                        dislikes={feedbacks_count_negative}
                                        currentUser={currentUser}
                                        dispatch={dispatch}
                                        { ...this.props.item }
                                    />
                                </div>
                            </div>
                            {
                                isShowCommentForm &&
                                <CommentForm
                                    user={currentUser}
                                    onSubmit={this.submitCommentHandler}
                                    namespace={namespace}
                                    files={this.props.files}
                                    dispatch={dispatch}
                                />
                            }
                        </div>
                        {
                            !isOpen &&
                            <div className="discussion-item__opener-container">
                                <Link style={{fontSize: '14px'}} >Далее</Link>
                            </div>
                        }
                    </div>
                </div>
                {
                    children && children.length > 0 &&
                    <div className="discussion-item__children">
                        {
                            children.map((item) => {
                                return (
                                    <Item
                                        key={item.id}
                                        type="children"
                                        item={item}
                                        files={this.props.files}
                                        currentUser={currentUser}
                                        dispatch={dispatch}
                                    />
                                );
                            })
                        }
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default Root;
