export const USER = {
    SET: 'SET_USER',
    UPDATE: 'UPDATE_USER'
};

export const QUESTION = {
    UPDATE: 'UPDATE_QUESTION',
    SELECT: 'SELECT_QUESTION',
    UPDATE_ANSWERS: 'UPDATE_ANSWERS_QUESTION',
    ADD_COMMENTS: 'ADD_COMMENTS_QUESTION',
    ADD_ANSWERS: 'ADD_ANSWERS_QUESTION',
    ANSWER_DISLIKE: 'ANSWER_DISLIKE_QUESTION',
    ANSWER_LIKE: 'ANSWER_LIKE_QUESTION',
    ANSWER_REDISLIKE: 'ANSWER_REDISLIKE_QUESTION'
};

export const QUESTIONS = {
    SET_LIST: 'SET_LIST_QUESTIONS',
    APPEND_LIST: 'APPEND_LIST_QUESTIONS',
    ADD_QUESTION: 'ADD_QUESTION_QUESTIONS',
    UPDATE_QUESTION: 'UPDATE_QUESTION_QUESTIONS'
};

export const FILES = {
    ADD: 'ADD_FILES',
    DELETE_FILE: 'DELETE_FILE',
    ERROR_FILE: 'ERROR_FILE',
    DONE_FILE: 'DONE_FILE',
    DELETING_FILE: 'DELETING_FILE',
    LOADING_FILE: 'LOADING_FILE',
    PROGRESS_FILE: 'PROGRESS_FILE',
    UPDATE_FILE_ID: 'UPDATE_FILE_ID',
    CLEAR: 'CLEAR_FILES',
    DELETE_ALL: 'DELETE_ALL_FILES'
};

export const FORM = {
    CLEAR: 'CLEAR_FORM',
    CHANGE: 'CHANGE_FORM',
    ON_PENDING: 'ON_PENDING_FORM',
    OFF_PENDING: 'OFF_PENDING_FORM',
    SET_DATA: 'SET_DATA_FORM'
};

export const BUFFER = {
    WRITE: 'WRITE_BUFFER',
    CLEAR: 'CLEAR_BUFFER'
};

export const PANEL = {
    CHANGE: 'CHANGE_PANEL',
    SET: 'SET_PANEL',
    LOCK: 'LOCK_PANEL',
    UNLOCK: 'UNLOCK_PANEL'
};

export const ALERT = {
    OPEN: 'OPEN_ALERT',
    CLOSE: 'CLOSE_ALERT'
};

export const MODAL = {
    OPEN: 'OPEN_MODAL',
    CLOSE: 'CLOSE_MODAL'
};

export const TUTORIAL = {
    SET: 'SET_TUTORIAL',
    SHOW: 'SHOW_TUTORIAL',
    HIDE: 'HIDE_TUTORIAL'
};

export const LOADING = {
    BREAK: 'BREAK_LOADING',
    RUN: 'RUN_LOADING'
};

export const NOTICE = {
    SHIFT: 'SHIFT_NOTICE',
    PUSH: 'PUSH_NOTICE',
    CLEAR: 'CLEAR_NOTICE',
    SET_STATUS: 'SET_STATUS_NOTICE'
};
