import { USER } from './../constants';
import STATUSES from './../statuses';
import actions from './index';
import launchParams from './../helpers/launch-params';
import { getToken, setToken } from './../utils/token';
import { authorize, register, getSelfUser, getTutorial, getSelfQuestions } from './../fetchers';



let getLaunchParams = () => ({ ...launchParams.get() });

let model = {
    asyncAuthorize: () => (dispatch, getState) => {
        let state = getState();

        let { user } = state;

        let getTokenByVKUserID = getToken(user.vk.id);
        let setTokenByVKUserID = setToken(user.vk.id);

        let getSelfUserRequest = () => {
            getSelfUser({token: getTokenByVKUserID()})
                .response()
                .OK()
                .subscribe((user) => {
                    user.map((user) => {
                        dispatch(model.set({data: user, type: 'prvd'}));
                        dispatch(actions.loading.run(['questions']));

                        getSelfQuestions({token: getTokenByVKUserID()})
                            .response()
                            .OK()
                            .subscribe((wrap) => {
                                dispatch(actions.loading.break(['main']));

                                wrap
                                    .filter((questions) => !questions.length)
                                    .map(() => dispatch(actions.loading.break(['questions'])));

                                wrap
                                    .filter((questions) => questions.length && questions.length > 0)
                                    .map((questions) => {
                                        dispatch(actions.questions.setList({list: questions}));
                                        dispatch(actions.panel.forward({active: 'questions'}));
                                        dispatch(actions.panel.unlock());
                                        dispatch(actions.loading.break(['questions']));
                                    });
                            });
                    });
                });
        };

        let registerRequest = () => {
            register({
                data: {
                    ...getLaunchParams(),
                    name: user.vk.first_name,
                    surname: user.vk.last_name,
                    city: user.vk.city ? user.vk.city.title : '',
                    gender: user.vk.sex,
                    birthdate: user.vk.bdate,
                    avatar: user.vk.photo_100
                }
            })
                .response()
                .OK()
                .subscribe((data) => {
                    data
                        .filter((data) => data.status === 'success')
                        .map((data) => data.access_token)
                        .map((token) => {
                            setTokenByVKUserID(token);
                            getSelfUser({token})
                                .response()
                                .OK()
                                .subscribe((data) => {
                                    data.map((data) => {
                                        dispatch(model.set({data, type: 'prvd'}));
                                        dispatch(actions.loading.break(['main']));
                                    });
                                });
                        });
                });
        };

        if (!getTokenByVKUserID()) {
            authorize({data: getLaunchParams()})
                .response()
                .OK()
                .subscribe((data) => {
                    data
                        .filter((data) => data.status === 'success')
                        .map((data) => data.access_token)
                        .map((token) => {
                            setTokenByVKUserID(token);
                            getSelfUserRequest();
                        });

                    data
                        .filter((data) => data.status === 'error')
                        .filter((data) => data.errors === 'Invalid credentials')
                        .map(() => {
                            getTutorial()
                                .response()
                                .OK()
                                .subscribe((items) => {
                                    items.map((items) => {
                                        dispatch(actions.tutorial.set({items}));
                                        dispatch(actions.loading.break(['tutorial']));
                                        dispatch(actions.tutorial.show());

                                        registerRequest();
                                    });
                                });
                        });
                });
        } else {
            getSelfUserRequest();
        }
    },

    set: (payload) => ({
        type: USER.SET,
        payload
    }),

    update: (payload) => ({
        type: USER.UPDATE,
        payload
    })
};

export default model;
