import React from 'react';
import classnames from 'classnames';
import { Avatar, Textarea, Spinner } from '@vkontakte/vkui';
import Icon28Send from '@vkontakte/icons/dist/28/send';
import Icon24Attach from '@vkontakte/icons/dist/24/attach';
import host from './../../../../../../../http/host';
import actions from './../../../../../../../actions';
import AttachedFiles from './../../../../../../attached-files/AttachedFiles';



const MIN_TEXT_LENGTH = 1;

class CommentForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            text: '',
            isPending: false
        };
    }

    addFilesHandler = (e) => {
        let { files } = e.target;
        let { dispatch, namespace } = this.props;
        dispatch(actions.files.asyncAdd({files, namespace}));
        e.target.value = '';
    };

    changeHandler = (e) => {
        let { value } = e.target;
        this.setState({
            text: value
        });
    };

    submitHandler = () => {
        if (!this.isValid()) {
            return;
        }
        if (this.isPending()) {
            return;
        }
        this.setState({
            isPending: true
        });
        let { onSubmit } = this.props;
        let { text } = this.state;
        text = text.trim();
        onSubmit && onSubmit({text}, {
            reject: () => {
                this.setState({
                    isPending: false
                });
            }
        });
    };

    isValid() {
        let { text } = this.state;
        return text.trim().length >= MIN_TEXT_LENGTH;
    }

    isPending() {
        let { isPending } = this.state;
        return isPending;
    }

    getAttachedFiles() {
        let { files, namespace } = this.props;
        files = files || {};
        files = files[namespace];
        return files || [];
    }

    render() {
        let { user, parentId, dispatch, namespace } = this.props;
        let { text } = this.state;
        let submitButtonClassName = classnames('comment-form__submit pointer', {
            'disabled': !this.isValid() || this.isPending()
        });
        let attachedFiles = this.getAttachedFiles();

        return (
            <div className="comment-form">
                <Avatar className="comment-form__avatar" src={host.get() + user.avatar} />
                <div className="comment-form__inner">
                    <div className="comment-form__main">
                        <div className="comment-form__textarea textarea">
                            <textarea
                                value={text}
                                onChange={this.changeHandler}>
                            </textarea>
                        </div>
                        <AttachedFiles
                            size="small"
                            files={attachedFiles}
                            namespace={namespace}
                            dispatch={dispatch}
                            className="comment-form__attached-files"
                        />
                    </div>
                    <div className="comment-form-bar">
                        <div className="comment-form-bar__item">
                            {
                                this.isPending() ?
                                <Spinner size="small" style={{width: '28px'}} /> :
                                <Icon28Send className={submitButtonClassName} fill="#2787f5" onClick={this.submitHandler} />
                            }
                        </div>
                        <div className="comment-form-bar__item">
                            <div className="comment-form__attach">
                                <input
                                    id={namespace}
                                    type="file"
                                    onChange={this.addFilesHandler}
                                    multiple
                                    accept=".png, .jpg, .jpeg, .jpe, .gif, .bmp, .tif, .tiff,
                                    .txt, .rtf, .csv, .xml, .doc, .docx, .odt, .xls,
                                    .xlsx, .odf, .ods, .pps, .ppt, .pptx, .pdf, .epub,
                                    .odf, .ods, .zip, .rar"
                                />
                                <label htmlFor={namespace} className="pointer">
                                    <Icon24Attach fill="#a7a7a7" />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommentForm;
