import React from 'react';
import { View, Panel, ConfigProvider } from '@vkontakte/vkui';
import Questions from './questions/Questions';
import Question from './question/Question';
import Form from './form/Form';
import Popup from './Popup';
import Modal from './Modal';
import actions from './../../actions';
import { getTheme } from './../../utils/theme';



let components = {
    'questions': Questions,
    'question': Question,
    'form': Form
};

class Panels extends React.Component {
    transitionHandler = () => {
        let { dispatch } = this.props;
        dispatch(actions.panel.unlock());
    };

    swipeBackHandler = () => {
        let { dispatch } = this.props;
        dispatch(actions.panel.back());
        window.history.back();
    };

    closePopupHandler = () => {
        let { dispatch } = this.props;
        dispatch(actions.alert.close());
    };

    scheme() {
        return getTheme();
    }

    render() {
        let { panel, alert, user, files, panels, questions, question, loading, modal, form, buffer, dispatch, onChangePanel } = this.props;

        return (
            <ConfigProvider scheme={this.scheme()}>
                <View
                    history={panel.history}
                    activePanel={panel.active}
                    onSwipeBack={this.swipeBackHandler}
                    onTransition={this.transitionHandler}
                    modal={<Modal modal={modal} dispatch={dispatch} user={user} />}
                    popout={alert ? <Popup onClose={this.closePopupHandler} dispatch={dispatch} user={user} { ...alert } /> : null}>
                    {
                        panels.map((panel) => {
                            let { name } = panel;
                            let Component = components[name];
                            return (
                                <Panel key={name} id={name}>
                                    <Component
                                        user={user}
                                        form={form}
                                        files={files}
                                        panel={panel}
                                        buffer={buffer}
                                        loading={loading}
                                        dispatch={dispatch}
                                        question={question}
                                        questions={questions}
                                        onChangePanel={onChangePanel}
                                    />
                                </Panel>
                            );
                        })
                    }
                </View>
            </ConfigProvider>
        );
    }
}

export default Panels;
