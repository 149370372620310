import React from 'react';
import InputMask from 'react-input-mask';
import { ModalPage, ModalPageHeader, Button } from '@vkontakte/vkui';
import './submit-phone-suggestion-modal.css';
import normalizePhone from './../../../../helpers/normalizePhone';
import vkConnectPromise from '@vkontakte/vkui-connect-promise';



class SubmitPhoneSuggestionModal extends React.Component {
    sended = false;
    requested = false;

    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            isDisabled: true
        };
    }

    changeHandler = (e) => {
        this.setState({
            phone: e.target.value
        });
    };

    focusHandler = () => {
        if (this.requested) {
            return;
        }
        this.requested = true;
        this.setState({
            isDisabled: false
        });
        vkConnectPromise.send('VKWebAppGetPhoneNumber')
            .then(({data}) => {
                let phone = data.phone_number;
                this.setState({
                    phone
                });
                this.focus();
            })
            .catch(() => this.focus());
    };

    skipHandler = () => {
        let { onSkip } = this.props;
        onSkip && onSkip();
    };

    sendHandler = () => {
        let { onSend } = this.props;
        if (this.sended) {
            return;
        }
        this.sended = true;
        onSend && onSend(this.getNormalizedPhone());
    };

    focus() {
        setTimeout(() => {
            this.refInput && this.refInput.focus();
        });
    }

    isValid() {
        return this.getNormalizedPhone().length === 11;
    }

    getNormalizedPhone() {
        let { phone } = this.state;
        return normalizePhone(phone);
    }

    setRefInput = (ref) => {
        this.refInput = ref;
    };

    render() {
        let { phone, isDisabled } = this.state;

        return (
            <div className="submit-phone-suggestion-modal">
                <div className="submit-phone-suggestion-modal__text" style={{marginBottom: '8px', textAlign: 'left'}}>Телефон, по которому с вами может связаться юрист для уточнения подробностей.<br />Доступен только нашему юристу.<br />Для вас звонок бесплатный.</div>
                <div onClick={this.focusHandler} className="submit-phone-suggestion-modal__control">
                    <InputMask
                        placeholder="+7"
                        inputmode="tel"
                        disabled={isDisabled}
                        className="input submit-phone-suggestion-modal__input"
                        mask="+7 (999) 999-99-99"
                        value={phone}
                        onChange={this.changeHandler}
                        inputRef={this.setRefInput}
                    />
                </div>
                <div>
                    <Button
                        onClick={this.sendHandler}
                        disabled={!this.isValid()}
                        className="submit-phone-suggestion-modal__control submit-phone-suggestion-modal__submit"
                        size="xl"
                        level="commerce">
                        Отправить
                    </Button>
                    <Button
                        onClick={this.skipHandler}
                        className="submit-phone-suggestion-modal__control"
                        size="xl"
                        level="secondary">
                        Пропустить
                    </Button>
                </div>
            </div>
        );
    }
}

export default SubmitPhoneSuggestionModal;
