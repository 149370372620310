import { LOADING } from './../constants';
import cloneDeep from 'lodash/cloneDeep';



let initialState = {
    main: true,
    question: false,
    tutorial: true
};

let reducer = (state = initialState, action) => {
    let { type } = action;
    switch (type) {
        case LOADING.BREAK: {
            let ids = action.payload;
            let copy = cloneDeep(state);
            ids.forEach((id) => copy[id] = false);
            return copy;
        }
        case LOADING.RUN: {
            let ids = action.payload;
            let copy = cloneDeep(state);
            ids.forEach((id) => copy[id] = true);
            return copy;
        }
        default: {
            return state;
        }
    }
};

export default reducer;
