import React from 'react';
import './item.css';
import pluralize from './../../../../helpers/pluralize';



const MAX_COUNT_CHARS_VISIBLE = 250;

class Item extends React.Component {
    clickHandler = () => {
        let { id, onClick } = this.props;
        onClick && onClick(id);
    };

    isAddEllipsis() {
        let { text, short_text } = this.props;
        text = text.replace('<p>', '').replace('</p>', '');
        return text.length !== short_text.length;
    }

    render() {
        let { short_text, title, answer_count } = this.props;

        short_text += this.isAddEllipsis() ? '...' : '';

        return (
            <li className="question-item questions__item" onClick={this.clickHandler}>
                <div className="question-item__title">{title}</div>
                <div className="question-item__text contentable" dangerouslySetInnerHTML={{ __html: short_text}} />
                {
                    answer_count > 0 ?
                    <span className="question-item__answers">{answer_count} {pluralize(answer_count, ['ответ', 'ответа', 'ответов'])}</span> :
                    <span className="question-item__answers">Нет ответов</span>
                }
            </li>
        );
    }
}

export default Item;
