import React from 'react';
import { Tabbar, TabbarItem } from '@vkontakte/vkui';
import { toTop } from './../../helpers/scroll';



class Tabs extends React.Component {
    changeHandler = (e) => {
        let { onChange } = this.props;
        onChange && onChange(e.currentTarget.dataset.name);
        setTimeout(() => {
            toTop();
        });
    };

    render() {
        let { items, activeTab } = this.props;

        return (
            <Tabbar>
                {
                    items.map((item) => {
                        let { Icon, name, text } = item;
                        return (
                            <TabbarItem
                                key={name}
                                selected={activeTab === name}
                                data-name={name}
                                text={text}
                                onClick={this.changeHandler}>
                                <Icon />
                            </TabbarItem>
                        );
                    })
                }
            </Tabbar>
        );
    }
}

export default Tabs;
