import host from './../http/host';
import { Request } from '@/jrequester';
import Maybe from '@/monads/maybe';



export default {
    get: () => {
        let request = new Request({
            method: 'GET',
            url: host.get() + '/rest/static/client-tutorial/',
            responseType: 'json'
        });

        request
            .response()
            .OK()
            .map((response) => (
                Maybe
                    .from(response)
                    .filter((response) => response.meta.code === 200)
                    .map((response) => response.data)
                    .map((data) => data.object)

            ));

        return request;
    }
};
