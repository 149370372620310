import React from 'react';
import Icon24Recent from '@vkontakte/icons/dist/24/recent';



class Empty extends React.Component {
    render() {
        return (
            <div className="discussion-empty">
                <Icon24Recent width="50" height="50" fill="#2975cc" className="discussion-empty__icon" />
                <div className="discussion-empty__title">Юристы уже работают над вашим вопросом.<br />Обычно ответы поступают в течение суток.</div>
            </div>
        );
    }
}

export default Empty;
