import React from 'react';
import { Button } from '@vkontakte/vkui';
import Icon16Down from '@vkontakte/icons/dist/16/down';



class Updater extends React.Component {
    render() {
        let { label, onClick } = this.props;

        return (
            <Button className="question-updater" before={<Icon16Down />} onClick={onClick}>{label}</Button>
        );
    }
}

export default Updater;
