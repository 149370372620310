import { ALERT } from './../constants';



let model = {
    open: (payload) => ({
        type: ALERT.OPEN,
        payload
    }),

    close: () => ({
        type: ALERT.CLOSE
    })
};

export default model;
