import { USER } from './../constants';
import cloneDeep from 'lodash/cloneDeep';
import update from 'immutability-helper';



let initialState = {
    vk: {},
    prvd: {}
};

let reducer = (state = initialState, action) => {
    let { type } = action;
    switch (type) {
        case USER.SET: {
            let { type, data } = action.payload;
            return update(state, {
                [type]: {
                    $set: data
                }
            });
        }
        case USER.UPDATE: {
            let { type, data } = action.payload;
            return update(state, {
                [type]: {
                    $merge: data
                }
            });
        }
        default: {
            return state;
        }
    }
};

export default reducer;
