import React from 'react';
import { Header, PanelHeader, HeaderButton, Spinner } from '@vkontakte/vkui';
import Icon24BrowserBack from '@vkontakte/icons/dist/24/browser_back';
import Card from './card/Card';
import Discussion from './discussion/Discussion';
import Updater from './updater/Updater';
import './question.css';
import cloneDeep from 'lodash/cloneDeep';
import pluralize from './../../../helpers/pluralize';
import actions from './../../../actions';



class Question extends React.Component {
    componentWillUnmount() {
        let { dispatch } = this.props;
        dispatch(actions.question.destroy());
    }

    componentDidMount() {
        let { question, dispatch } = this.props;
        if (!question.current) {
            dispatch(actions.question.asyncSelect());
        }
    }

    getDetails() {
        let { question: { comments, current } } = this.props;

        let details = [];

        if (!current) {
            return [];
        }

        let { discussion_tree } = current;

        discussion_tree && discussion_tree.forEach((entity) => {
            let { entity_type, parent_entity_id, entity_id } = entity;
            if (entity_type === 'qcomment') {
                let comment = comments[entity_id];
                comment && !comment.answer_id && details.push(comment);
            }

        });

        return details;
    }

    getDiscussion() {
        let { question: { answers, comments, current } } = this.props;

        if (!current) {
            return [];
        }

        let { discussion_tree } = current;

        let discussion = [];

        if (Object.keys(answers).length === 0 && Object.keys(comments).length === 0) {
            return [];
        }

        discussion_tree && discussion_tree.forEach((entity, index) => {
            let { entity_id, entity_type } = entity;

            if (this.isRootAnswer(entity)) {
                let answer = answers[entity_id];
                let copyTree = cloneDeep(discussion_tree);

                copyTree.splice(index, 1);
                answer && discussion.push({
                    ...answer,
                    type: entity_type,
                    children: [ ...this.getChildren(entity_id, copyTree) ]
                });
            }
        });

        return discussion;
    }

    isRootAnswer(entity) {
        let { entity_type, parent_entity_id } = entity;
        return entity_type === 'answer' && !parent_entity_id;
    }

    getChildren(parentId, tree) {
        let children = [];
        let { question: { answers, comments } } = this.props;

        tree.forEach((item, index) => {
            let { entity_id, parent_entity_id, entity_type } = item;
            if (parent_entity_id === parentId) {
                let entities = [];

                switch (entity_type) {
                    case 'answer':
                        entities = answers;
                        break;
                    case 'qcomment':
                        entities = comments;
                        break;
                    default:
                        break;
                }

                let entity = entities[entity_id];
                let copyTree = cloneDeep(tree);

                copyTree.splice(index, 1);
                children.push({
                    ...entity,
                    type: entity_type
                });
                children = [
                    ...children,
                    ...this.getChildren(entity_id, copyTree)
                ];
            }
        });

        return children;
    };

    isThereNewAnswers() {
        let newAnswers = this.getNewAnswers();
        return Object.keys(newAnswers).length > 0;
    }

    getUpdaterLabel() {
        let newAnswers = this.getNewAnswers();
        let newAnswersCount = Object.keys(newAnswers).length;
        let label = '';
        label += newAnswersCount + ' ';
        label += pluralize(newAnswersCount, ['новый', 'новых', 'новых']) + ' ';
        label += pluralize(newAnswersCount, ['ответ', 'ответа', 'ответов']);
        return label;
    }

    getNewAnswers() {
        let { buffer, question: { current } } = this.props;
        return buffer[current.id] ? buffer[current.id].answers : {};
    }

    getNewDiscussionTree() {
        let { buffer, question: { current } } = this.props;
        return buffer[current.id] ? buffer[current.id].tree : [];
    }

    applyUpdatesAnswers() {
        let newAnswers = this.getNewAnswers();
        let newDiscussionTree = this.getNewDiscussionTree();

        let { dispatch, question } = this.props;

        dispatch(actions.question.update({
            data: {
                current: {
                    ...question.current,
                    discussion_tree: [ ...newDiscussionTree ]
                }
            }
        }));

        dispatch(actions.question.addAnswers({items: newAnswers}));
        dispatch(actions.buffer.clear());
    }

    goBack() {
        let { onChangePanel } = this.props;
        onChangePanel && onChangePanel('questions');
    }

    clickUpdaterHandler = () => {
        this.applyUpdatesAnswers();
    };

    clickBackHandler = () => {
        this.goBack();
    };

    render() {
        let { user, dispatch, files, question, loading } = this.props;

        let details = this.getDetails();
        let discussion = this.getDiscussion();

        return (
            <React.Fragment>
                <PanelHeader
                    left={<HeaderButton onClick={this.clickBackHandler}><Icon24BrowserBack /></HeaderButton>}>
                    {
                        !question.current || loading.question ?
                        <Spinner size="small" /> :
                        <span>Вопрос №{question.current.id}</span>
                    }
                </PanelHeader>
                {
                    !question.current || loading.question ?
                    <Spinner size="large" style={{marginTop: '50px'}} /> :
                    <div className="question">
                        {
                            this.isThereNewAnswers() &&
                            <Updater label={this.getUpdaterLabel()} onClick={this.clickUpdaterHandler} />
                        }
                        <Card { ...question.current } dispatch={dispatch} details={details} attachedFiles={files} loading={loading.details} />
                        {
                            loading.discussion ?
                            <Spinner size="medium" /> :
                            <Discussion user={user} discussion={discussion} dispatch={dispatch} files={files} />
                        }
                    </div>
                }
            </React.Fragment>
        );
    }
}

export default Question;
