import React from 'react';
import Icon24CommentOutline from '@vkontakte/icons/dist/24/comment_outline';
import RelativeDate from './../../../../../../../../RelativeDate';
import Rater from './../../../../../rater/Rater';
import CommentForm from './../../../comment-form/CommentForm';
import actions from './../../../../../../../../../actions';
import { scroller } from 'react-scroll';



class AnswerFooter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isShowCommentForm: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        let { isShowCommentForm: isShowCommentFormPrev } = prevState;
        let { isShowCommentForm: isShowCommentFormCurr } = this.state;
        let isFormClosed = isShowCommentFormPrev && !isShowCommentFormCurr;
        isFormClosed && this.deleteAllFiles();
    }

    deleteAllFiles() {
        let { dispatch } = this.props;
        dispatch(actions.files.asyncDeleteAll({namespace: this.namespace()}));
    }

    toggleCommentForm() {
        let { isShowCommentForm } = this.state;
        this.setState({
            isShowCommentForm: !isShowCommentForm
        });
    }

    namespace() {
        let { item: { id } } = this.props;
        return 'parent_id_' + id;
    }

    addCommentClickHandler = () => {
        this.toggleCommentForm();
    };

    submitCommentHandler = ({text}, callbacks) => {
        let { dispatch, item: { id } } = this.props;
        let namespace = this.namespace();
        let { reject } = callbacks;
        dispatch(actions.question.asyncAddComment({
            namespace,
            data: {
                text,
                parent_entity_type: 'answer',
                parent_entity_id: id
            }
        }, (entity) => {
            this.setState({
                isShowCommentForm: false
            });
            setTimeout(() => {
                scroller.scrollTo('qcomment-' + entity.id, {
                    duration: 800,
                    delay: 0,
                    offset: -70,
                    smooth: 'easeInOutQuart'
                });
            });
        }, () => {}, () => {
            reject && reject();
        }));
    };

    render() {
        let { item: { timeadd, feedbacks_count_negative, feedbacks_count_positive }, currentUser, files, dispatch } = this.props;
        let { isShowCommentForm } = this.state;

        let namespace = this.namespace();

        return (
            <React.Fragment>
                <div className="discussion-item__footer">
                    <div className="discussion-item__footer-left">
                        <div className="discussion-item__meta">
                            <RelativeDate datetime={timeadd} />
                        </div>
                        <div className="discussion-item__reply pointer" onClick={this.addCommentClickHandler}>
                            <Icon24CommentOutline fill="#a7a7a7" />
                        </div>
                    </div>
                    <div className="discussion-item__footer-right">
                        <Rater
                            utensils="my"
                            role="client"
                            likes={feedbacks_count_positive}
                            dislikes={feedbacks_count_negative}
                            currentUser={currentUser}
                            dispatch={dispatch}
                            { ...this.props.item }
                        />
                    </div>
                </div>
                {
                    isShowCommentForm &&
                    <CommentForm
                        user={currentUser}
                        onSubmit={this.submitCommentHandler}
                        namespace={namespace}
                        files={files}
                        dispatch={dispatch}
                    />
                }
            </React.Fragment>
        );
    }
}

export default AnswerFooter;
