import React from 'react';
import { Cell, Avatar } from '@vkontakte/vkui';
import host from './../../../../../../../../../http/host';



class UserAnswer extends React.Component {
    getName() {
        let { user } = this.props;
        user = user || {};
        let { name, surname } = user;
        let result = '';
        if (name && surname) {
            result += name + ' ' + surname;
        } else {
            if (name) {
                result += name;
            } else if (surname) {
                result += surname;
            }
        }
        return result;
    }

    getDescription() {
        let specialization = this.getSpecialization();
        let city = this.getCity();
        let result = '';
        if (specialization && city) {
            result += specialization + ', ' + city;
        } else {
            if (specialization) {
                result += specialization;
            } else if (city) {
                result += city;
            }
        }
        return result;
    }

    getCity() {
        let { user } = this.props;
        user = user || {};
        let { city_label, city_name } = user;
        let result = '';
        if (city_label && city_name) {
            result += city_label + '. ' + city_name;
        } else {
            if (city_name) {
                result += city_name;
            }
        }
        return result;
    }

    getSpecialization() {
        let { user } = this.props;
        user = user || {};
        let { specialization_name } = user;
        return specialization_name || '';
    }

    render() {
        let { user } = this.props;
        user = user || {};

        return (
            <Cell
                description={this.getDescription()}
                before={<Avatar src={user.avatar} />}
            >
                {this.getName()}
            </Cell>
        );
    }
}

export default UserAnswer;
