import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/ru';



export class RelativeDate extends Component {

    refreshInterval = null;

    constructor(props) {
        super(props);

        this.initState = this.initState.bind(this);
        this.getString = this.getString.bind(this);
        this.updateString = this.updateString.bind(this);

        this.state = {
            timeStr: null,
            dateTime: props.datetime
        };
    }

    initState(props) {
        const now = Math.floor(Date.now() / 1000);
        const datetime = props.datetime <= now ? props.datetime : now;

        this.setState({dateTime: datetime});
    }

    getString() {
        let now = Math.floor(Date.now() / 1000);
        let datetime = this.state.dateTime <= now ? this.state.dateTime : now;

        return moment.unix(datetime, 'X').fromNow();
    }

    updateString() {
        this.setState({timeStr: this.getString()});
    }

    componentDidMount() {
        this.updateString();
        this.refreshInterval = setInterval(this.updateString, 10000);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({dateTime: nextProps.datetime});
        this.updateString();
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    render() {
        return <span title={moment.unix(this.state.dateTime, 'X').format('DD-MM-YYYY, HH:mm')}>{this.state.timeStr}</span>;
    }

}

export default RelativeDate;
