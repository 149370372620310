import { QUESTIONS } from './../constants';
import actions from './index';
import { getToken } from './../utils/token';
import { getSelfQuestions } from './../fetchers';



let getSelfList = (state, limit, offset) => (
    getSelfQuestions({
        token: getToken(state.user.vk.id)(),
        limit,
        offset
    }).response()
);

let model = {
    asyncGet: ({success} = {}) => (dispatch, getState) => {
        getSelfList(getState())
            .OK()
            .subscribe((wrap) => {
                wrap.map((list) => {
                    dispatch(model.setList({list}));
                    success && success(list);
                });
            });
    },

    asyncLoad: (limit, offset, {success} = {}) => (dispatch, getState) => {
        getSelfList(getState(), limit, offset)
            .OK()
            .subscribe((wrap) => {
                wrap.map((list) => {
                    dispatch(model.appendList({list}));
                    success && success(list);
                });
            });
    },

    setList: (payload) => ({
        type: QUESTIONS.SET_LIST,
        payload
    }),

    appendList: (payload) => ({
        type: QUESTIONS.APPEND_LIST,
        payload
    }),

    addQuestion: (payload) => ({
        type: QUESTIONS.ADD_QUESTION,
        payload
    }),

    updateQuestion: (payload) => ({
        type: QUESTIONS.UPDATE_QUESTION,
        payload
    })
};

export default model;
