import { QUESTION } from './../constants';
import update from 'immutability-helper';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';



let initialState = {
    current: null,
    answers: {},
    comments: {}
};

let reducer = (state = initialState, action) => {
    let { type } = action;
    switch (type) {
        case QUESTION.SELECT: {
            let { question } = action.payload;
            return update(state, {
                current: {
                    $set: question
                }
            });
        }
        case QUESTION.UPDATE: {
            let { data } = action.payload;
            let copy = cloneDeep(state);
            return merge(copy, data);
        }
        case QUESTION.ADD_COMMENTS: {
            let { items } = action.payload;
            return update(state, {
                comments: {
                    $merge: items
                }
            });
        }
        case QUESTION.ADD_ANSWERS: {
            let { items } = action.payload;
            return update(state, {
                answers: {
                    $merge: items
                }
            });
        }
        case QUESTION.UPDATE_ANSWERS: {
            let { items } = action.payload;
            let copy = cloneDeep(state.answers);
            merge(copy, items);
            return update(state, {
                answers: {
                    $set: copy
                }
            });
        }
        case QUESTION.ANSWER_DISLIKE: {
            let { id, feedback } = action.payload;
            let answer = state.answers[id];
            answer = answer || {};
            let value = answer.feedbacks_count_negative;
            let feedbacks = answer.answer_feedbacks;
            feedbacks = feedbacks || {};
            feedbacks = update(feedbacks, {
                [feedback.id]: {
                    $set: feedback
                }
            });
            return update(state, {
                answers: {
                    [id]: {
                        feedbacks_count_negative: {
                            $set: value + 1
                        },
                        answer_feedbacks: {
                            $set: feedbacks
                        }
                    }
                }
            });
        }
        case QUESTION.ANSWER_LIKE: {
            let { id, feedback } = action.payload;
            let answer = state.answers[id];
            answer = answer || {};
            let value = answer.feedbacks_count_positive;
            let feedbacks = answer.answer_feedbacks;
            feedbacks = feedbacks || {};
            feedbacks = update(feedbacks, {
                [feedback.id]: {
                    $set: feedback
                }
            });
            return update(state, {
                answers: {
                    [id]: {
                        feedbacks_count_positive: {
                            $set: value + 1
                        },
                        answer_feedbacks: {
                            $set: feedbacks
                        }
                    }
                }
            });
        }
        case QUESTION.ANSWER_REDISLIKE: {
            let { id, feedback } = action.payload;
            let answer = state.answers[id];
            answer = answer || {};
            let value = answer.feedbacks_count_negative;
            let feedbacks = answer.answer_feedbacks;
            feedbacks = feedbacks || {};
            feedbacks = cloneDeep(feedbacks);
            delete feedbacks[feedback.id];
            return update(state, {
                answers: {
                    [id]: {
                        feedbacks_count_negative: {
                            $set: value - 1
                        },
                        answer_feedbacks: {
                            $set: feedbacks
                        }
                    }
                }
            });
        }
        default: {
            return state;
        }
    }
};

export default reducer;
