import React from 'react';
import UserAnswer from './UserAnswer';
import UserComment from './UserComment';



let components = {
    answer: UserAnswer,
    qcomment: UserComment
};

class User extends React.Component {
    render() {
        let { type, user, currentUser } = this.props;
        let Component = components[type];

        return (
            <Component user={user} currentUser={currentUser} />
        );
    }
}

export default User;
