import React from 'react';
import './index.css';
import Notice from './components/notice/Notice';
import Container from './components/Container';
import { connect } from 'react-redux';
import actions from './actions';



class App extends React.Component {
    panelChangeHandler = (panel, params) => {
        let { dispatch } = this.props;
        dispatch(actions.panel.forward({active: panel, params}));
    };

    render() {
        let { dispatch, notice } = this.props;

        return (
            <React.Fragment>
                <Notice dispatch={dispatch} { ...notice } />
                <Container { ...this.props } onChangePanel={this.panelChangeHandler} />
            </React.Fragment>
        );
    }
}

export default connect((state) => ({
    tutorial: state.tutorial,
    user: state.user.prvd,
    form: state.form,
    alert: state.alert,
    modal: state.modal,
    panel: state.panel,
    files: state.files,
    buffer: state.buffer,
    question: state.question,
    questions: state.questions,
    loading: state.loading,
    notice: state.notice
}))(App);
