import { MODAL } from './../constants';



let reducer = (state = {name: null}, action) => {
    let { type } = action;
    switch (type) {
        case MODAL.OPEN: {
            let { data } = action.payload;
            return data;
        }
        case MODAL.CLOSE: {
            return {name: null};
        }
        default: {
            return state;
        }
    }
};

export default reducer;
