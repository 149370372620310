export let getCurrentUserFeedbackState = (currentUserId, feedbacks) => {
    feedbacks = feedbacks || {};
    let feedbacksKeys = Object.keys(feedbacks);
    let state = 'not_rate';
    for (let i = 0; i < feedbacksKeys.length; i++) {
        let feedback = feedbacks[feedbacksKeys[i]];
        let { user, is_positive } = feedback;
        if (user.id == currentUserId) {
            return is_positive ? 'rate_positive' : 'rate_negative';
        }
    }
    return state;
};


export let getCurrentUserFeedback = (currentUserId, feedbacks) => {
    feedbacks = feedbacks || {};
    let feedbacksKeys = Object.keys(feedbacks);
    for (let i = 0; i < feedbacksKeys.length; i++) {
        let feedback = feedbacks[feedbacksKeys[i]];
        let { user } = feedback;
        if (user.id == currentUserId) {
            return feedback;
        }
    }
    return null;
};
