import React from 'react';
import './form.css';
import { FormLayout, Textarea, Button, File, PanelHeader, Spinner } from '@vkontakte/vkui';
import Icon24Document from '@vkontakte/icons/dist/24/document';
import AttachedFiles from './../../attached-files/AttachedFiles';
import Progress from './progress/Progress';
import actions from './../../../actions';
import formStorage from './../../../storages/form';
import text from './../../../helpers/text';



const MIN_LENGTH_TEXT = 100;
const MAX_LENGTH_TEXT = 16000;
const NAMESPACE = 'question';

let progressStates = {
    1: {title: 'Вопрос слишком короткий', color: 'red'},
    [MIN_LENGTH_TEXT]: {title: 'Отличный вопрос!', color: 'green'},
    [MAX_LENGTH_TEXT + 1]: {title: 'Вопрос слишком длинный', color: 'red'}
};

class Form extends React.Component {
    componentDidMount() {
        let { dispatch } = this.props;
        let restoredData = formStorage.restore('data');
        let restoredFiles = formStorage.restore('files');
        restoredFiles = restoredFiles || {};
        if (restoredData) {
            dispatch(actions.form.setData({data: restoredData}));
            dispatch(actions.files.add({files: restoredFiles.items ? restoredFiles.items : [], namespace: NAMESPACE}));
        }
    }

    componentDidUpdate() {
        let { form, dispatch, files } = this.props;
        files = files[NAMESPACE];
        files = files || [];
        formStorage.save('data', form.data);
        formStorage.save('files', {items: files});
    }

    addFilesHandler = (e) => {
        let { files } = e.target;
        let { dispatch } = this.props;
        dispatch(actions.files.asyncAdd({files, namespace: NAMESPACE}));
        e.target.value = '';
    };

    changeHandler = (e) => {
        let { value } = e.target;
        let { dispatch } = this.props;
        dispatch(actions.form.change({
            name: 'question',
            value: {
                text: value
            }
        }));
    };

    submitHandler = () => {
        if (this.isPending()) {
            return;
        }
        if (this.isDisabled()) {
            return;
        }
        let { dispatch } = this.props;
        dispatch(actions.form.asyncSubmit());
    };

    normalize() {
        let { form: { data: { question } } } = this.props;
        return text.normalize(question.text);
    }

    isPending() {
        let { form: { pending } } = this.props;
        return pending;
    }

    isDisabled() {
        let text = this.normalize();
        return text.length < MIN_LENGTH_TEXT || text.length > MAX_LENGTH_TEXT;
    }

    getProgressState() {
        let state = null;
        let text = this.normalize();

        Object.keys(progressStates).forEach((progressStateKey) => {
            progressStateKey = parseInt(progressStateKey, 10);
            if (text.length >= progressStateKey) {
                state = progressStates[progressStateKey];
                state.percent = text.length > MIN_LENGTH_TEXT ? 100 : text.length * 100 / MIN_LENGTH_TEXT;
            }
        });

        return state;
    }

    render() {
        let { files, form: { data }, user, dispatch } = this.props;
        let progressState = this.getProgressState();

        return (
            <React.Fragment>
                <PanelHeader>Задайте вопрос юристу</PanelHeader>
                <div className="form">
                    <div className="form__title">Ваш вопрос</div>
                    <div className="form__textarea textarea">
                        <textarea
                            value={data.question.text}
                            onChange={this.changeHandler}>
                        </textarea>
                        <Progress data={progressState} />
                    </div>
                    <AttachedFiles files={files[NAMESPACE]} namespace={NAMESPACE} dispatch={dispatch} className="form__files" />
                    <File
                        multiple
                        className="form__file pointer"
                        before={<Icon24Document />}
                        size="xl"
                        level="2"
                        children="Добавить файлы"
                        onChange={this.addFilesHandler}
                        accept=".png, .jpg, .jpeg, .jpe, .gif, .bmp, .tif, .tiff,
                        .txt, .rtf, .csv, .xml, .doc, .docx, .odt, .xls,
                        .xlsx, .odf, .ods, .pps, .ppt, .pptx, .pdf, .epub,
                        .odf, .ods, .zip, .rar"
                    />
                    <Button
                        disabled={this.isDisabled() || this.isPending()}
                        onClick={this.submitHandler}
                        className="form__submit pointer"
                        size="xl"
                        level="commerce">
                        {
                            this.isPending() ?
                            <Spinner size="small" className="form__loader" /> :
                            'Задать вопрос юристу'
                        }
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}

export default Form;
