import { QUESTIONS } from './../constants';
import update from 'immutability-helper';
import cloneDeep from 'lodash/cloneDeep';



let reducer = (state = [], action) => {
    let { type } = action;
    switch (type) {
        case QUESTIONS.SET_LIST: {
            let { list } = action.payload;

            return list || [];
        }

        case QUESTIONS.APPEND_LIST: {
            let { list } = action.payload;

            let copy = cloneDeep(state);

            return copy.concat(list);
        }

        case QUESTIONS.ADD_QUESTION: {
            let { entity } = action.payload;
            return update(state, {
                $push: [entity]
            });
        }

        case QUESTIONS.UPDATE_QUESTION: {
            let { id, data } = action.payload;
            let targetIndex = state.findIndex((item) => parseInt(item.id, 10) === parseInt(id, 10));
            return update(state, {
                [targetIndex]: {
                    $merge: data
                }
            });
        }

        default: {
            return state;
        }
    }
};

export default reducer;
