import { ALERT } from './../constants';



let reducer = (state = null, action) => {
    let { type } = action;
    switch (type) {
        case ALERT.OPEN: {
            let { data } = action.payload;
            return data;
        }
        case ALERT.CLOSE: {
            return null;
        }
        default: {
            return state;
        }
    }
};

export default reducer;
